import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  KTSVG,
  apiBaseURL,
  checkEmpty,
  fetchAsyncDropdown,
  fetchAsyncDropdownNew,
  isEmpty,
  numberFormat,
} from '../../../_eaFruitsDms/helpers'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {useNavigate} from 'react-router-dom'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import usePermission from '../../../_eaFruitsDms/helpers/components/usePermission'

const RouteManagement: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'All Routes',
    subTitle: 'All Routes',
  }

  const initialRoute = {
    idle_time: '',
    avg_serving_time: '',
    name: '',
    sales_supervisor_name: '',
    streets: [],
    sales_team: null,
    created_by_type: '',
    created_by_name: '',
    supervisor: '',
  }

  const initialValidation = {
    idle_time: '',
    supervisor: '',
    avg_serving_time: '',
    name: '',
    sales_supervisor_name: '',
    streets: '',
    sales_team: '',
  }

  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [routes, setRoutes] = useState<any>([])
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [showRouteModal, setShowRouteModal] = useState(false)
  const [id, setId] = useState('')
  const [offset, setOffset] = useState(0)
  const [list, setList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [salesTeam, setSalesTeam] = useState<any>('')
  const [streetsOffset, setStreetsOffset] = useState(0)
  const [streetsList, setStreetsList] = useState([])
  const [streetsDropDownSearch, setStreetsDropDownSearch] = useState('')
  const [routeDetails, setRouteDetails] = useState<any>(initialRoute)
  const [validation, setValidation] = useState<any>(initialValidation)
  const [resetSalesTeam, setResetSalesTeam] = useState<any>(moment())
  const [resetStreetsTeam, setResetStreetsTeam] = useState<any>(moment())
  const [showAlert, setShowAlert] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState('Submit')
  const [uniqError, setUniqError] = useState<any>({})
  const [isDetailsLoading, setDetailsLoading] = useState(false)

  // supervisor modal filter state
  const [supervisorOffset, setSupervisorOffset] = useState('')
  const [supervisorList, setSupervisorList] = useState([])
  const [supervisorSearch, setSupervisorSearch] = useState('')

  const navigate = useNavigate()
  const debounceSearch = useDebounce(searchByName, 500)
  const permission = usePermission('route_management')

  useEffect(() => {
    fetchRoutes()
  }, [currentOffset, debounceSearch, salesTeam])

  const fetchRoutes = async () => {
    setDisplayLoader(true)

    const url = `${apiBaseURL()}routes?limit=${limit}&offset=${currentOffset}&ordering=id&search=${debounceSearch}&sales_team_id=${
      salesTeam?.value ?? ''
    }`

    const response = await getRequest(url, true)

    if (!isEmpty(response.results)) {
      setRoutes(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const fetchSalesTeam = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/sales-team?ordering=name',
      search,
      setDropDownSearch,
      'name',
      true,
      'All Sales Team'
    )
  }

  const fetchSalesTeamModal = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      'master/sales-team?ordering=name',
      search,
      setDropDownSearch,
      'name'
    )
  }

  const fetchStreets = async (search: any) => {
    return fetchAsyncDropdown(
      streetsOffset,
      setStreetsOffset,
      streetsList,
      setStreetsList,
      `routes/street/drop-down?sales_team_id=${routeDetails?.sales_team?.value}`,
      search,
      setStreetsDropDownSearch,
      'name'
    )
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e
    setCurrentOffset(0)
    setSelected(0)

    switch (inputName) {
      case 'salesTeam':
        setSalesTeam(inputValue)
        break
      case 'search':
        setSearchByName(inputValue)
        break
      default:
        setSearchByName(inputValue)
        break
    }
  }

  const streetsTooltip = (items: any) => {
    if (items && items.length > 0) {
      if (items.length > 1) {
        return (
          <OverlayTrigger
            delay={{hide: 450, show: 300}}
            overlay={(props) => (
              <Tooltip {...props} className='tooltip-dark'>
                {items.map((item: any, index: any) => {
                  return (
                    <>
                      <span>
                        {index ? ',' : ''} {item.street_name}{' '}
                      </span>
                    </>
                  )
                })}
              </Tooltip>
            )}
            placement='top'
          >
            <span className='cursor-pointer'>
              {items[0].street_name}
              {` & ${items.length - 1} more`}
            </span>
          </OverlayTrigger>
        )
      } else {
        return <span className='cursor-pointer'>{items[0].street_name}</span>
      }
    } else {
      return <></>
    }
  }

  const checkValid = () => {
    let valid = true
    let details = {...routeDetails}
    let validationHandler = {...validation}

    if (isEmpty(details.name)) {
      valid = false
      validationHandler.name = 'is-invalid'
    }

    if (details.streets.length === 0) {
      valid = false
      validationHandler.streets = 'is-invalid'
    }

    if (isEmpty(details.sales_team)) {
      valid = false
      validationHandler.sales_team = 'is-invalid'
    }

    if (isEmpty(details.idle_time)) {
      valid = false
      validationHandler.idle_time = 'is-invalid'
    }

    if (isEmpty(details.avg_serving_time)) {
      valid = false
      validationHandler.avg_serving_time = 'is-invalid'
    }

    if (isEmpty(details.supervisor)) {
      valid = false
      validationHandler.supervisor = 'is-invalid'
    }

    setValidation(validationHandler)

    return valid
  }

  const updateRouteDetails = async () => {
    let valid = checkValid()

    if (valid) {
      setIsModalLoading('Please Wait...')

      const url = `${apiBaseURL()}routes/${id}/update`

      const body = {
        name: routeDetails?.name ?? null,
        sales_team: routeDetails?.sales_team?.value ?? null,
        streets: routeDetails.streets ? routeDetails.streets.map((i: any) => i.value) : [],
        avg_ideal_time: routeDetails?.idle_time ?? null,
        avg_serving_time: routeDetails?.avg_serving_time ?? null,
        manage_by: routeDetails?.supervisor?.value ?? null,
      }

      const response = await patchRequest(url, body, true)

      let successArray = [201, 200]
      let failArray = [400, 500]

      successArray.includes(response.status) && responseSuccess()
      failArray.includes(response.status) && responseFail(response)
    }
  }

  const responseSuccess = () => {
    clear()
    fetchRoutes()
    setShowAlert(true)
    setIsModalLoading('Submit')
  }

  const responseFail = (response: any) => {
    setIsModalLoading('Submit')

    if (response && response.data && response.data.error) {
      if (response.data.error.field === 'combination_already_exists') {
        setUniqError(response?.data?.error?.data ?? [])
      }
    }
  }

  const showError = () => {
    const error = uniqError

    if (error && error.route_name) {
      return (
        <p className='text-danger'>
          Route should be paired with unique Streets to avoid duplicate combinations.{' '}
          {error?.streets.map((item: any, index: any) => {
            return (
              <>
                <b>
                  {index ? ',' : ''} {item.street_name}{' '}
                </b>
              </>
            )
          })}{' '}
          are already assigned
        </p>
      )
    }

    return ''
  }

  const clear = () => {
    setValidation(initialValidation)
    setRouteDetails(initialRoute)
    setId('')
    setShowRouteModal(false)
  }

  const fetchRouteDetails = async (id: string) => {
    setDetailsLoading(true)

    const url = `${apiBaseURL()}routes/${id}`

    const response = await getRequest(url, true)

    setRouteDetails({
      idle_time: response?.avg_ideal_time ?? '',
      avg_serving_time: response?.avg_serving_time ?? '',
      name: response?.name ?? '',
      sales_supervisor_name: response?.sales_supervisor_name ?? '',
      supervisor:
        response?.manage_by_id && response?.manage_by_name
          ? {value: response?.manage_by_id, label: response?.manage_by_name}
          : '',
      created_by_name: response?.created_by_name ?? '',
      created_by_type: response?.created_by_type ?? '',
      streets: isEmpty(response?.streets)
        ? []
        : response.streets.map((i: any) => ({
            value: i.street_id,
            label: i.street_name,
          })),
      sales_team: isEmpty(response?.sales_team_id)
        ? null
        : {
            value: response?.sales_team_id,
            label: response?.sales_team_name,
          },
    })

    setDetailsLoading(false)
  }

  const fetchSupervisor = (search?: any) => {
    let warehouseId = getW_Id

    return fetchAsyncDropdownNew(
      supervisorOffset,
      setSupervisorOffset,
      supervisorList,
      setSupervisorList,
      `auth/users/team/list?type=ss&warehouse_id=${warehouseId}&warehouse__id=${warehouseId}&ordering=name`,
      search,
      setSupervisorSearch,
      'first_name',
      'last_name',
      false
    )
  }

  return (
    <>
      <EATitle title='All Routes' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'All Routes',
        })}
      </PageTitle>

      <EaAlertSuccess
        newProps={true}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={'Route Updated Successfully.'}
      />

      <div className={`card ${className}`}>
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar onChange={handleFilterChange} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <AsyncPaginate
                loadOptions={fetchSalesTeam}
                onChange={(e: any) => {
                  handleFilterChange(e, 'salesTeam')
                }}
                isSearchable
                placeholder='All Sales Team'
                isDisabled={displayLoader}
                value={salesTeam}
                className='react-select-container my-1 me-3 w-150px'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              {/* <button className='btn btn-primary' type='button' onClick={() => {}}>
                Create Route
              </button> */}
            </div>
          </div>
        </div>
        <div className='card-body pt-0 pb-3'>
          <div className='table-responsive'>
            {displayLoader ? (
              <FetchingRecords />
            ) : routes.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-100px'>Route No.</th>
                    <th className='min-w-100px'>Route Name</th>
                    <th className='min-w-100px'>Sales Team</th>
                    <th className='min-w-140px'>Sales Supervisor</th>
                    <th className='min-w-100px'>Total Streets</th>
                    <th className='min-w-140px'>Total Customers</th>
                    <th className='min-w-150px'>Avg. Serving Time</th>
                    <th className='min-w-150px'>Avg. Idle Time</th>
                    {/* {permission.write && <th className='min-w-100px'>Action</th>} */}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {routes.map((routes: any) => {
                    return (
                      <tr className='cursor-pointer'>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.route_no) ? '-' : `#${routes.route_no}`}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes.name)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes.sales_team_name)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes?.manage_by_name)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.street_count)
                            ? '-'
                            : numberFormat(routes?.street_count).displayFormat}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {checkEmpty(routes.customer_count)}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.avg_serving_time)
                            ? '-'
                            : `${numberFormat(routes.avg_serving_time).displayFormat} ${
                                routes.avg_serving_time > 0 ? 'Mins' : 'Min'
                              }`}
                        </td>
                        <td
                          onClick={() => {
                            navigate('/route/all-route-details', {
                              state: {
                                routeId: routes.id,
                              },
                            })
                          }}
                        >
                          {isEmpty(routes.avg_ideal_time)
                            ? '-'
                            : `${numberFormat(routes.avg_ideal_time).displayFormat} ${
                                routes.avg_ideal_time > 0 ? 'Mins' : 'Min'
                              }`}
                        </td>

                        {/* {permission.write && (
                          <td>
                            <div
                              onClick={() => {
                                setId(routes.id)
                                setShowRouteModal(true)
                                fetchRouteDetails(routes.id)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='ic mr-0'
                              />
                            </div>
                          </td>
                        )} */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <>
        <Modal
          show={showRouteModal}
          onHide={() => {
            setShowRouteModal(false)
            clear()
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='call-notes-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>Edit Route</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                clear()
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            {isDetailsLoading ? (
              <FetchingRecords />
            ) : (
              <>
                <div className=''>
                  <label htmlFor=''>Route Name</label>
                  <div className='p-1'>
                    <input
                      type='text'
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, name: e.target.value})
                        setValidation({...validation, name: ''})
                      }}
                      placeholder='Enter Route Name'
                      value={routeDetails.name}
                      className={clsx('form-control', validation.name)}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor='' className='mb-1'>
                    Sales Supervisor
                  </label>
                  {/* <div className='p-1'>
                    <input
                      type='text'
                      placeholder='Sales Supervisor'
                      value={`${checkEmpty(routeDetails.created_by_name)}`}
                      disabled
                      className={clsx('form-control')}
                    />
                  </div> */}
                  <AsyncPaginate
                    loadOptions={fetchSupervisor}
                    onChange={(e: any) => {
                      setRouteDetails({...routeDetails, supervisor: e})
                      setValidation({...validation, supervisor: ''})
                    }}
                    isSearchable
                    placeholder='Select'
                    value={routeDetails.supervisor}
                    className={clsx('react-select-container', validation.supervisor)}
                    classNamePrefix='react-select'
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Sales Team</label>
                  <div className='p-1'>
                    <AsyncPaginate
                      key={resetSalesTeam}
                      loadOptions={fetchSalesTeamModal}
                      isSearchable
                      placeholder='Select'
                      value={routeDetails.sales_team}
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, streets: [], sales_team: e})
                        setValidation({...validation, sales_team: ''})
                      }}
                      onBlur={() => {
                        setResetSalesTeam(moment())
                        setOffset(0)
                        setList([])
                        setDropDownSearch('')
                      }}
                      className={clsx('react-select-container', validation.sales_team)}
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Streets</label>
                  <div className='p-1'>
                    <AsyncPaginate
                      key={resetStreetsTeam}
                      loadOptions={fetchStreets}
                      isSearchable
                      placeholder='Select'
                      isMulti
                      value={routeDetails.streets}
                      onChange={(e) => {
                        setRouteDetails({...routeDetails, streets: e})
                        setValidation({...validation, streets: ''})
                      }}
                      onBlur={() => {
                        setResetStreetsTeam(moment())
                        setStreetsOffset(0)
                        setStreetsList([])
                        setStreetsDropDownSearch('')
                      }}
                      isDisabled={isEmpty(routeDetails?.sales_team?.value)}
                      className={clsx('react-select-container', validation.streets)}
                      classNamePrefix='react-select'
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Avg. Serving Time</label>
                  <div className='p-1'>
                    <div
                      className={clsx('input-group  p-0')}
                      style={{
                        borderWidth: validation.avg_serving_time === 'is-invalid' ? 1 : '',
                        borderColor: validation.avg_serving_time === 'is-invalid' ? 'red' : '',
                        borderStyle: validation.avg_serving_time === 'is-invalid' ? 'solid' : '',
                      }}
                    >
                      <input
                        type='text'
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setRouteDetails({...routeDetails, avg_serving_time: e.target.value})
                            setValidation({...validation, avg_serving_time: ''})
                          }
                        }}
                        placeholder='Enter Idle Time'
                        className={clsx('form-control border-start-0', validation.avg_serving_time)}
                        value={routeDetails.avg_serving_time}
                      />
                      <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                        Mins
                      </span>
                    </div>
                  </div>
                </div>

                <div className='mt-4'>
                  <label htmlFor=''>Avg. Idle Time</label>
                  <div className='p-1'>
                    <div
                      className={clsx('input-group  p-0')}
                      style={{
                        borderWidth: validation.idle_time === 'is-invalid' ? 1 : '',
                        borderColor: validation.idle_time === 'is-invalid' ? 'red' : '',
                        borderStyle: validation.idle_time === 'is-invalid' ? 'solid' : '',
                      }}
                    >
                      <input
                        type='text'
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setRouteDetails({...routeDetails, idle_time: e.target.value})
                            setValidation({...validation, idle_time: ''})
                          }
                        }}
                        placeholder='Enter Idle Time'
                        className={clsx('form-control border-start-0', validation.idle_time)}
                        value={routeDetails.idle_time}
                      />
                      <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0'>
                        Mins
                      </span>
                    </div>
                  </div>
                </div>

                <div className='mt-3'>{showError()}</div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  clear()
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  updateRouteDetails()
                }}
                disabled={isModalLoading !== 'Submit'}
              >
                {isModalLoading}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>

      <CustomPaginate
        data={routes}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
      />
    </>
  )
}

export default RouteManagement
