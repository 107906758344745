import clsx from 'clsx'
import $ from 'jquery'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  bulkPriceCreateUpdate,
  ManageAllPriceList,
  ManagePriceList,
} from '../../../_eaFruitsDms/apiFunctions/priceDiscount/priceDiscount'
import {
  apiBaseURL,
  categoryOptions,
  checkPermissions,
  dateFormat,
  defaultPermission,
  getLoginWarehouse,
  GLOBAL_DATE_FORMAT,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
  removeBlur,
  retrieveFilter,
  saveFilter,
} from '../../../_eaFruitsDms/helpers'
import {categoryStyle} from '../../../_eaFruitsDms/helpers/components/categoryStyle'
import FormatMessage from '../../../_eaFruitsDms/helpers/components/FormateMessage'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import ValidationHandler from '../../../_eaFruitsDms/helpers/components/validationHandler'
import DatePickerCustom from '../../../_eaFruitsDms/helpers/datePicker'
import {getRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertDanger, EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import EaButton from '../../../_eaFruitsDms/layout/components/button/eaButton'
import ResetButton from '../../../_eaFruitsDms/layout/components/button/resetButton'
import ErrorHandler from '../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'
import InfoIcon from '../../../_eaFruitsDms/layout/components/header/infoIcon'
import IsLoader from '../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import SkuThumbnail from '../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {getW_Id} from '../../../_eaFruitsDms/localStorage/getItem/getLocalStorage'
import {filters} from '../../../_eaFruitsDms/utils/FilterConstants'
import tenantConfiguration from '../../../TenantVariables'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

type Props = {
  className: string
}

const ManagePrice: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Manage Price',
    subTitle: 'Price & Discounts',
  }

  let managePriceFiltered: any = retrieveFilter(filters.wm_manage_price)

  const initialState = {
    filterBrand: managePriceFiltered?.filterBrand || {label: 'All Brands', value: ''},
    filterCategory: managePriceFiltered?.filterCategory || {label: 'All Categories', value: ''},
    filterGroups: managePriceFiltered?.filterGroups || {label: 'All Product Groups', value: ''},
    futureDate: managePriceFiltered?.futureDate || false,
    selected: managePriceFiltered?.selected || '',
    search: managePriceFiltered?.search || '',
    currentOffSet: managePriceFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const initialPrice = {
    wholesalePriceSingle: '',
    wholesaleExpirySingle: '',
    retailPriceSingle: '',
    retailExpirySingle: '',
    reason_note: '',
    disableReasonNote: false,
    is_default_price_set: false,
    default_wholesale_price: 0,
    default_wholesale_expiry_price: 0,
    default_retail_price: 0,
    default_retail_expiry_price: 0,
  }

  const [getList, setGetList] = useState<any>([])
  const [getListModal, setGetListNodal] = useState<any>([])
  const [isModalDetailsFetching, setIsModalDetailsFetching] = useState(false)
  const [count, setCount] = useState<string>('1')
  const [getLengthModal, setLengthModal] = useState('1')
  const [listLoader, setListLoader] = useState<any>(false)
  const [fetchingFirstRoute, setFetchingFirstRoute] = useState(false)
  const [timer] = useState<any>(null)
  const [search, setSearch] = useState<any>(managePriceFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(managePriceFiltered?.currentOffSet || '0')
  const [selected, setSelected] = useState<number>(managePriceFiltered?.selected || 0)
  const [filterBrand, setFilterBrand] = useState<any>(
    managePriceFiltered?.filterBrand || {
      label: 'All Brands',
      value: '',
    }
  )
  const [filterCategory, setFilterCategory] = useState<any>(
    managePriceFiltered?.filterCategory || {
      label: 'All Categories',
      value: '',
    }
  )
  const [filterGroups, setFilterGroups] = useState<any>(
    managePriceFiltered?.filterGroups || {
      label: 'All Product Groups',
      value: '',
    }
  )
  const [type, setType] = useState('warehouse')
  const [route, setRoute] = useState<any>(null)
  const [brandValueModal, setBrandValueModal] = useState<any>('')
  const [categoryValueModal, setCategoryValueModal] = useState<any>('')
  const [groupsValueModal, setGroupsValueModal] = useState<any>('')
  const [filterBrandModal, setFilterBrandModal] = useState<any>('')
  const [filterCategoryModal, setFilterCategoryModal] = useState<any>('')
  const [filterGroupsModal, setFilterGroupsModal] = useState<any>('')
  const [existingPrices, setExistingPrices] = useState<any>([])
  const [masterArray, setMasterArray] = useState<any>([])
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [dateShow, setDateShow] = useState(moment().format(GLOBAL_DATE_FORMAT))
  const [dateShowSingle, setDateShowSingle] = useState<any>({
    dateApi: moment(new Date()).format('YYYY-MM-DD'),
    dateView: new Date(),
    setDateShowInput: '',
  })
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successEdit')
  const [messageTitle, setMessageTitle] = useState('')
  const [brandOffset, setBrandInOffset] = useState<any>('')
  const [routeOffset, setRouteOffset] = useState<any>('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [groupOffset, setGroupsOffset] = useState<any>('')
  const [limit] = useState<number>(tenantConfiguration.limit)
  const [brandOption, setBrandOption] = useState<any>([])
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [groupsOption, setGroupsOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [priceValue, setPriceValue] = useState<any>(initialPrice)
  const [validation, setValidation] = useState({
    wholesalePriceSingle: '',
    wholesaleExpirySingle: '',
    retailPriceSingle: '',
    retailExpirySingle: '',
    date: '',
    reason_note: '',
  })
  const [bulkValidationRetail, setBulkValidationRetail] = useState<any>([])
  const [bulkValidationWholesale, setBulkValidationWholesale] = useState<any>([])
  const [bulkValidationReasonNote, setBulkValidationReasonNote] = useState<any>([])
  const [unit, setUnit] = useState('')
  const [futureDate, setFutureDate] = useState<any>(managePriceFiltered?.futureDate || false)
  const [permission, setPermission] = useState(defaultPermission)
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    managePriceFiltered?.buttonDisable || false
  )
  const [error, setError] = useState('')

  const debounceSearch = useDebounce(search, 500)

  const isRouteTab = type === 'route'
  const routeId = isRouteTab ? route?.value ?? '' : ''

  useEffect(() => {
    fetchPermission()

    setIsModalDetailsFetching(true)

    ManageAllPriceList('', '', '', futureDate, routeId).then((res: any) => {
      setGetListNodal(res)

      if (res.length > 0) {
        let updatedValidation: any = [...bulkValidationWholesale]
        let updatedValidationRetail: any = [...bulkValidationRetail]
        let updatedValidationReasonNote: any = [...bulkValidationReasonNote]

        res.map((item: any) => {
          updatedValidation.push({product_id: item.id, validation: '', borderValidation: ''})
          updatedValidationRetail.push({product_id: item.id, validation: '', borderValidation: ''})
          updatedValidationReasonNote.push({
            product_id: item.id,
            validation: '',
            borderValidation: '',
          })
        })

        setBulkValidationRetail(updatedValidationRetail)
        setBulkValidationWholesale(updatedValidation)
        setBulkValidationReasonNote(updatedValidationReasonNote)
      }

      setIsModalDetailsFetching(false)
    })
  }, [bulkValidationRetail.length == 0, route])

  useEffect(() => {
    setListLoader(true)
    ManagePriceList(
      limit,
      currentOffset,
      debounceSearch,
      filterBrand.value,
      filterCategory.value,
      filterGroups.value,
      futureDate,
      routeId
    ).then((res: any) => {
      setGetList(res.data)

      if (res.data.length > 0) {
        let updatedPriceData: any = []

        res.data.map((data: any) => {
          let valid = true

          if (isRouteTab && !data.is_default_price_set) {
            valid = false
          }

          if (valid && data.product_sku_price.length > 0) {
            return updatedPriceData.push({
              id: data.product_sku_price[0].id,
              action_by: data.product_sku_price[0].action_by,
              effective_date: data.product_sku_price[0].effective_date,
              product_id: data.product_sku_price[0].product_id,
              retail_expiry_price: Number(data.product_sku_price[0].retail_expiry_price),
              retail_price: Number(data.product_sku_price[0].retail_price),
              wholesale_expiry_price: Number(data.product_sku_price[0].wholesale_expiry_price),
              wholesale_price: Number(data.product_sku_price[0].wholesale_price),
              warehouse_id: data.product_sku_price[0].warehouse_id,
              reason_note: data.product_sku_price[0].reason_note,
            })
          }
        })

        setExistingPrices(updatedPriceData)
      }

      setListLoader(false)
    })

    let updatedState: any = {...filterStates}

    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_manage_price, filterStates)

    if (
      filterCategory.value == '' &&
      filterBrand.value == '' &&
      filterGroups.value == '' &&
      search == '' &&
      futureDate == false
    ) {
      setButtonDisable(false)
    }
  }, [
    currentOffset,
    filterBrand,
    filterCategory,
    filterGroups,
    debounceSearch,
    futureDate,
    routeId,
  ])

  useEffect(() => {
    setBrandInOffset('')
    setCategoryOffset('')
    setGroupsOffset('')
    setRouteOffset('')
  }, [searchSelect])

  const fetchPermission = async () => {
    let res = await checkPermissions('price_and_discount_price_management')
    setPermission(res)
  }

  const fetchBrand = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const url = `${apiBaseURL()}master/product/brand?ordering=name&search=${search}&limit=${limit}&offset=${brandOffset}`

    const response = await getRequest(url, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setBrandInOffset(newOffset)
      hasMore = true
    }

    if (brandOption.length == 0) {
      options.push({
        label: 'All Brand',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (brandOption.length > 0) {
      setBrandOption(brandOption.concat(options))
    } else {
      setBrandOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchRoute = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const url = `${apiBaseURL()}routes/drop-down?ordering=name&search=${search}&limit=${limit}&offset=${routeOffset}`

    const response = await getRequest(url, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setRouteOffset(newOffset)
      hasMore = true
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        let concatName = option?.name ?? ''

        if (!isEmpty(option?.sales_team_name)) {
          concatName = (
            <span>
              {option.name} - <b>{option?.sales_team_name}</b>
            </span>
          )
        }

        options.push({
          label: concatName,
          value: option.id,
        })
      })
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const url = `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`

    const response = await getRequest(url, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setCategoryOffset(newOffset)
      hasMore = true
    }

    if (categoryOption.length == 0) {
      options.push({
        label: 'All Category',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
          ...categoryOptions,
        })
        if (option.child_category.length > 0) {
          option.child_category.map((child: any) => {
            options.push({
              label: child.name,
              value: child.id,
            })
          })
        }
      })
    }

    if (categoryOption.length > 0) {
      setCategoryOption(categoryOption.concat(options))
    } else {
      setCategoryOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchProductsGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const url = `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffset}`

    const response = await getRequest(url, true)

    let hasMore: boolean = false

    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setGroupsOffset(newOffset)
      hasMore = true
    }

    if (groupsOption.length == 0) {
      options.push({
        label: 'All Product Groups',
        value: '',
      })
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (groupsOption.length > 0) {
      setGroupsOption(groupsOption.concat(options))
    } else {
      setGroupsOption(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const searchList = (e: any) => {
    setFilterStates({...filterStates, ['search']: e.target.value})
    setButtonDisable(true)
    clearTimeout(timer)
    setSearch(e.target.value)
  }

  const getBulkValue = (e: any, item: any, index?: any) => {
    setExistingPrices([])
    let bulkId = e.target.id
    let productId = item.id
    let checkedValue = item.product_sku_price.length
    let customMasterArr = masterArray

    const {name, value} = e.target

    const updatedBulkValidationWholesale: any = [...bulkValidationWholesale]

    const availableIndexWholesale: any = bulkValidationWholesale.findIndex((retail: any) => {
      return retail && retail.product_id == item.id
    })

    const availableIndexRetail = bulkValidationRetail.findIndex((retail: any) => {
      return retail && retail.product_id == item.id
    })

    const updatedBulkValidationRetail: any = [...bulkValidationRetail]

    try {
      const index = masterArray.findIndex((item: any) => {
        return item && item.id == bulkId
      })

      if (index !== -1) {
        customMasterArr[bulkId][name] = value

        if (name === 'wholesalePrice') {
          customMasterArr[bulkId]['wholesaleExpiry'] = value
          updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
          updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
        }

        if (name === 'wholesaleExpiry' && value == '') {
          customMasterArr[bulkId]['wholesaleExpiry'] = ''
        }

        if (name === 'retailPrice') {
          customMasterArr[bulkId]['retailExpiry'] = value
          updatedBulkValidationRetail[availableIndexRetail].validation = ''
          updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
        }

        if (name === 'retailExpiry' && value == '') {
          customMasterArr[bulkId]['retailExpiry'] = ''
        }
      } else {
        if (customMasterArr[bulkId] == undefined) {
          customMasterArr[bulkId] = {
            id: item.id,
            checkedValue: checkedValue,
            productId: productId,
            product: checkedValue > 0 ? item?.product_sku_price[0] : null,
            default_wholesale_price: isEmpty(item?.default_wholesale_price)
              ? ''
              : item?.default_wholesale_price,
            default_wholesale_expiry_price: isEmpty(item?.default_wholesale_expiry_price)
              ? ''
              : item?.default_wholesale_expiry_price,
            default_retail_price: isEmpty(item?.default_retail_price)
              ? ''
              : item?.default_retail_price,
            default_retail_expiry_price: isEmpty(item?.default_retail_expiry_price)
              ? ''
              : item?.default_retail_expiry_price,
            [name]: value,
          }

          if (name === 'wholesalePrice') {
            customMasterArr[bulkId]['wholesaleExpiry'] = value
            updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
            updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
          }

          if (name === 'wholesaleExpiry' && value == '') {
            customMasterArr[bulkId]['wholesaleExpiry'] = ''
          }

          if (name === 'retailPrice') {
            customMasterArr[bulkId]['retailExpiry'] = value
            updatedBulkValidationRetail[availableIndexRetail].validation = ''
            updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
          }

          if (name === 'retailExpiry' && value == '') {
            customMasterArr[bulkId]['retailExpiry'] = ''
          }
        } else {
          customMasterArr[bulkId][name] = value

          if (name === 'wholesalePrice') {
            customMasterArr[bulkId]['wholesaleExpiry'] = value
            updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
            updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
          }

          if (name === 'wholesaleExpiry' && value == '') {
            customMasterArr[bulkId]['wholesaleExpiry'] = ''
          }

          if (name === 'retailPrice') {
            customMasterArr[bulkId]['retailExpiry'] = value
            updatedBulkValidationRetail[availableIndexRetail].validation = ''
            updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
          }

          if (name === 'retailExpiry' && value == '') {
            customMasterArr[bulkId]['retailExpiry'] = ''
          }
        }
      }

      setMasterArray(customMasterArr)
    } catch (error) {}
  }

  const clearFunction = () => {
    setMessageTitle('')
    setGroupsOffset('')
    setCategoryOffset('')
    setBrandInOffset('')
    setMasterArray([])
    setDateShow(moment().format(GLOBAL_DATE_FORMAT))
    setInitialSettings((pre: any) => ({...pre, startDate: moment()}))
    setDate(moment(new Date()).format('YYYY-MM-DD'))
    setBulkValidationRetail([])
    setBulkValidationWholesale([])
    setBulkValidationReasonNote([])
    setError('')

    $('#tableId')
      .find('input[type=text]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  const bulkValidation = () => {
    let isValid: boolean = true

    masterArray.map((item: any, index: any) => {
      const availableIndexRetail = bulkValidationRetail.findIndex((retail: any) => {
        return retail && retail.product_id == item.id
      })

      const updatedBulkValidationRetail: any = [...bulkValidationRetail]

      const availableIndexWholesale = bulkValidationWholesale.findIndex((wholesale: any) => {
        return wholesale && wholesale.product_id == item.id
      })

      const updatedBulkValidationWholesale: any = [...bulkValidationWholesale]

      const updatedBulkValidationReasonNote: any = [...bulkValidationReasonNote]

      if (isRouteTab) {
        const {
          default_wholesale_price,
          default_wholesale_expiry_price,
          default_retail_price,
          default_retail_expiry_price,
          retailExpiry,
          retailPrice,
          wholesaleExpiry,
          wholesalePrice,
        } = item

        const checkReasonRequired =
          default_wholesale_price > Number(wholesalePrice) ||
          default_wholesale_expiry_price > Number(wholesaleExpiry) ||
          default_retail_price > Number(retailPrice) ||
          default_retail_expiry_price > Number(retailExpiry)

        if (availableIndexWholesale > -1) {
          if (checkReasonRequired && isEmpty(item?.reason_note?.trim())) {
            isValid = false
            updatedBulkValidationReasonNote[availableIndexWholesale].validation = 'is-invalid'
            updatedBulkValidationReasonNote[availableIndexWholesale].borderValidation =
              'is-invalid-grp'
          } else {
            updatedBulkValidationReasonNote[availableIndexWholesale].validation = ''
            updatedBulkValidationReasonNote[availableIndexWholesale].borderValidation = ''
          }

          setBulkValidationReasonNote(updatedBulkValidationReasonNote)
        }
      }
    })

    return isValid
  }

  const updateBulkPrice = () => {
    const isValid: any = bulkValidation()

    if (isValid) {
      setIsLoading('Please Wait...')
      let masterData: any = []

      if (existingPrices.length === 0) {
        masterArray.forEach((element: any) => {
          if (
            element.wholesalePrice !== '' &&
            element.wholesaleExpiry !== '' &&
            element.retailPrice !== '' &&
            element.retailExpiry !== ''
          ) {
            if (element.checkedValue === 0) {
              const routeID = !isEmpty(routeId)
                ? {
                    route_id: routeId,
                    is_default: false,
                    reason_note: isEmpty(element?.reason_note?.trim())
                      ? null
                      : element?.reason_note,
                  }
                : {is_default: true}

              masterData.push({
                product_id: element.id,
                warehouse_id: getW_Id,
                effective_date: date,
                wholesale_price: element.wholesalePrice ? Number(element.wholesalePrice) : '',
                wholesale_expiry_price: element.wholesaleExpiry
                  ? Number(element.wholesaleExpiry)
                  : '',
                retail_price: element.retailPrice ? Number(element.retailPrice) : '',
                retail_expiry_price: element.retailExpiry ? Number(element.retailExpiry) : '',
                action_by: 'Eafruits',
                ...routeID,
              })
            } else {
              getListModal.find((item: any) => {
                let valid = true

                if (isRouteTab && !item.is_default_price_set) {
                  valid = false
                }

                if (valid && item.product_sku_price.length !== 0) {
                  if (item.product_sku_price[0].product_id == element.id) {
                    const routeID = !isEmpty(routeId)
                      ? {
                          route_id: routeId,
                          is_default: false,
                          reason_note: element.reason_note
                            ? element.reason_note
                            : item.product_sku_price[0].reason_note,
                        }
                      : {is_default: true}

                    masterData.push({
                      id: item.product_sku_price[0].id,
                      product_id: item.product_sku_price[0].product_id,
                      warehouse_id: getW_Id,
                      effective_date: date,
                      wholesale_price: element.wholesalePrice
                        ? Number(element.wholesalePrice)
                        : Number(item.product_sku_price[0].wholesale_price),
                      wholesale_expiry_price: element.wholesaleExpiry
                        ? Number(element.wholesaleExpiry)
                        : Number(item.product_sku_price[0].wholesale_expiry_price),
                      retail_price: element.retailPrice
                        ? Number(element.retailPrice)
                        : Number(item.product_sku_price[0].retail_price),
                      retail_expiry_price: element.retailExpiry
                        ? Number(element.retailExpiry)
                        : Number(item.product_sku_price[0].retail_expiry_price),
                      action_by: 'Eafruits',
                      ...routeID,
                    })
                  }
                }
              })
            }
          }
        })
      }

      setDisableBtn(true)

      bulkPriceCreateUpdate(masterData).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')

        $('#tableId')
          .find('input[type=number]')
          .each(function () {
            var defaultVal = $(this).data('default')
            $(this).val(defaultVal)
          })

        if (res.success) {
          setIsAlertSuccess(true)
          setListLoader(true)
          ManagePriceList(
            limit,
            currentOffset,
            search,
            filterBrand.value,
            filterCategory.value,
            filterGroups.value,
            futureDate,
            routeId
          ).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setListLoader(false)
            setSelected(0)
          })
        }

        if (res.failed) {
          if (res.errorField === 'reason_not_found') {
            setError('Please enter reason note')

            return false
          }

          if (res.errorField === 'default_price_not_found') {
            setError('warehouse default price is not found')

            return false
          }

          setIsAlertFailed(true)
        }

        $('#closeBulModal').trigger('click')

        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      })
    }
  }

  const filterSearchBrand = (val: any, key: any) => {
    let label = ''

    if (val.label === 'All Brands') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setBrandValueModal(label)
      setFilterBrandModal(val)
      setListLoader(true)
      setIsModalDetailsFetching(true)

      ManageAllPriceList(label, categoryValueModal, groupsValueModal, futureDate, routeId).then(
        (res) => {
          setGetListNodal(res)
          if (res.length == 0) {
            setLengthModal('2')
          } else {
            setLengthModal('1')
          }
          setListLoader(false)
          setIsModalDetailsFetching(false)
        }
      )
    }

    if (key === 'LIST') {
      setFilterBrand(val)
      setListLoader(true)
      setFilterStates({...filterStates, ['filterBrand']: val})
      setButtonDisable(true)
      setCurrentOffset(0)
      setSelected(0)
    }
  }

  const filterSearchRoute = (val: any, key: any) => {
    if (key === 'LIST') {
      setRoute(val)
      setListLoader(true)
      setButtonDisable(true)
      setCurrentOffset(0)
      setSelected(0)
    }
  }

  const filterSearchCategory = (val: any, key: any) => {
    let label = ''
    if (val.label === 'All Categories') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setFilterCategoryModal(val)
      setCategoryValueModal(label)
      setListLoader(true)
      setIsModalDetailsFetching(true)

      ManageAllPriceList(brandValueModal, label, groupsValueModal, futureDate, routeId).then(
        (res) => {
          setGetListNodal(res)
          if (res.length == 0) {
            setLengthModal('2')
          } else {
            setLengthModal('1')
          }
          setListLoader(false)
          setIsModalDetailsFetching(false)
        }
      )
    }

    if (key === 'LIST') {
      setFilterCategory(val)
      setListLoader(true)
      setCurrentOffset(0)
      setSelected(0)
      setFilterStates({...filterStates, ['filterCategory']: val})
      setButtonDisable(true)
    }
  }

  const filterSearchGroups = (val: any, key: any) => {
    let label = ''

    if (val.label === 'All Groups') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setFilterGroupsModal(val)
      setGroupsValueModal(label)
      setListLoader(true)
      setIsModalDetailsFetching(true)

      ManageAllPriceList(brandValueModal, categoryValueModal, label, futureDate, routeId).then(
        (res) => {
          setGetListNodal(res)
          if (res.length == 0) {
            setLengthModal('2')
          } else {
            setLengthModal('1')
          }
          setListLoader(false)
          setIsModalDetailsFetching(false)
        }
      )
    }

    if (key === 'LIST') {
      setFilterGroups(val)
      setListLoader(true)
      setCurrentOffset(0)
      setSelected(0)
      setFilterStates({...filterStates, ['filterGroups']: val})
      setButtonDisable(true)
    }
  }

  const onApply = () => {
    removeBlur()
  }

  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  const updateSinglePrice = async () => {
    let isValid: boolean = true

    if (isRouteTab && !priceValue?.is_default_price_set) {
      isValid = false

      return
    }

    if (priceValue.wholesalePriceSingle === '') {
      isValid = false
      setValidation((preValue: any) => ({...preValue, wholesalePriceSingle: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, wholesalePriceSingle: ''}))
    }

    if (Number(priceValue.wholesaleExpirySingle) > Number(priceValue.wholesalePriceSingle)) {
      isValid = false
      setValidation((preValue: any) => ({...preValue, wholesaleExpirySingle: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, wholesaleExpirySingle: ''}))
    }

    if (priceValue.retailPriceSingle === '') {
      isValid = false
      setValidation((preValue: any) => ({...preValue, retailPriceSingle: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, retailPriceSingle: ''}))
    }

    if (Number(priceValue.retailExpirySingle) > Number(priceValue.retailPriceSingle)) {
      isValid = false
      setValidation((preValue: any) => ({...preValue, retailExpirySingle: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, retailExpirySingle: ''}))
    }

    const {
      default_wholesale_price,
      default_wholesale_expiry_price,
      default_retail_price,
      default_retail_expiry_price,
    } = priceValue

    const checkDisable = !(
      default_wholesale_price > Number(priceValue.wholesalePriceSingle) ||
      default_wholesale_expiry_price > Number(priceValue.wholesaleExpirySingle) ||
      default_retail_price > Number(priceValue.retailPriceSingle) ||
      default_retail_expiry_price > Number(priceValue.retailExpirySingle)
    )

    if (isRouteTab && !checkDisable && isEmpty(priceValue?.reason_note?.trim())) {
      isValid = false
      setValidation((preValue: any) => ({...preValue, reason_note: 'is-invalid'}))
    } else {
      setValidation((preValue: any) => ({...preValue, reason_note: ''}))
    }

    if (!isValid) {
      return false
    }

    if (priceValue.wholesalePriceSingle !== '' && priceValue.retailPriceSingle !== '') {
      const {id, product_id, action_by} = priceValue.priceItem

      setDisableBtn(true)
      setIsLoading('Please Wait...')

      let masterData: any = []

      const routeID = routeId
        ? {
            route_id: routeId,
            is_default: false,
            reason_note: isEmpty(priceValue?.reason_note) ? null : priceValue?.reason_note,
          }
        : {is_default: true}

      masterData.push({
        id: id,
        product_id: product_id === undefined ? priceValue.productId : product_id,
        warehouse_id: getW_Id,
        effective_date: dateShowSingle.dateApi,
        wholesale_price: Number(priceValue.wholesalePriceSingle),
        wholesale_expiry_price: Number(priceValue.wholesaleExpirySingle),
        retail_price: Number(priceValue.retailPriceSingle),
        retail_expiry_price: Number(priceValue.retailExpirySingle),
        action_by: action_by,
        show_future_price: futureDate,
        ...routeID,
      })

      bulkPriceCreateUpdate(masterData).then((res) => {
        setDisableBtn(false)
        setIsLoading('Submit')

        if (res.success) {
          setIsAlertSuccess(true)
          setIsModalDetailsFetching(true)

          ManageAllPriceList('', '', '', futureDate, routeId).then((res) => {
            setGetListNodal(res)
            setIsModalDetailsFetching(false)
          })

          setListLoader(true)

          ManagePriceList(
            limit,
            currentOffset,
            search,
            filterBrand.value,
            filterCategory.value,
            filterGroups.value,
            futureDate,
            routeId
          ).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setListLoader(false)
          })
        }

        if (res.failed) {
          if (res.errorField === 'reason_not_found') {
            setError('Please enter reason note')

            return false
          }

          if (res.errorField === 'default_price_not_found') {
            setError('warehouse default price is not found')

            return false
          }

          return false
        }

        $('#closeSingleModal').trigger('click')

        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      })
    }
  }

  const clearSingleModal = () => {
    setInitialSettings((pre: any) => ({...pre, startDate: moment()}))
    setValidation({
      retailExpirySingle: '',
      retailPriceSingle: '',
      wholesaleExpirySingle: '',
      wholesalePriceSingle: '',
      date: '',
      reason_note: '',
    })

    setPriceValue(initialPrice)
    setError('')
  }

  const handleBlur = (e: any) => {
    const {value, name} = e.target

    if (name === 'wholesaleExpirySingle') {
      if (value === '') {
        setPriceValue((prevState: any) => ({
          ...prevState,
          ['wholesaleExpirySingle']: priceValue.wholesalePriceSingle,
        }))
        setValidation({...validation, ['wholesaleExpirySingle']: ''})
      }
    }

    if (name === 'retailExpirySingle') {
      if (value === '') {
        setPriceValue((prevState: any) => ({
          ...prevState,
          ['retailExpirySingle']: priceValue.retailPriceSingle,
        }))
        setValidation({...validation, ['retailExpirySingle']: ''})
      }
    }
  }

  const checkReasonNote = () => {
    if (isRouteTab) {
      const {
        default_wholesale_price,
        default_wholesale_expiry_price,
        default_retail_price,
        default_retail_expiry_price,
      } = priceValue

      const checkDisable = !(
        default_wholesale_price > Number(priceValue.wholesalePriceSingle) ||
        default_wholesale_expiry_price > Number(priceValue.wholesaleExpirySingle) ||
        default_retail_price > Number(priceValue.retailPriceSingle) ||
        default_retail_expiry_price > Number(priceValue.retailExpirySingle)
      )

      setPriceValue((prevState: any) => ({
        ...prevState,
        disableReasonNote: checkDisable,
        reason_note: checkDisable ? '' : prevState?.reason_note ?? '',
      }))
    }
  }

  const singleBulkChange = (e: any) => {
    const {value, name} = e.target

    setPriceValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))

    if (name === 'wholesalePriceSingle') {
      setPriceValue((prevState: any) => ({
        ...prevState,
        ['wholesaleExpirySingle']: value,
      }))

      setValidation({...validation, ['wholesaleExpirySingle']: ''})
    }

    if (name === 'wholesaleExpirySingle') {
      if (parseInt(value) > parseInt(priceValue.wholesalePriceSingle)) {
        setValidation((preValue: any) => ({
          ...preValue,
          wholesaleExpirySingle: 'is-invalid',
        }))
      } else {
        setValidation((preValue: any) => ({
          ...preValue,
          wholesaleExpirySingle: '',
        }))
      }
    }

    if (name === 'retailPriceSingle') {
      setPriceValue((prevState: any) => ({
        ...prevState,
        ['retailExpirySingle']: value,
      }))

      setValidation({...validation, ['retailExpirySingle']: ''})
    }

    if (name === 'retailExpirySingle') {
      if (parseInt(value) > parseInt(priceValue.retailPriceSingle)) {
        setValidation((preValue: any) => ({
          ...preValue,
          retailExpirySingle: 'is-invalid',
        }))
      } else {
        setValidation((preValue: any) => ({
          ...preValue,
          retailExpirySingle: '',
        }))
      }
    }
  }

  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    drops: 'up',
    showDropdowns: true,
  })

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d)
          setDate(newDate.apiDate)
          setDateShow(newDate.displayDate)
          setInitialSettings((pre: any) => ({...pre, startDate: start._d}))
          onApply()
        }}
        initialSettings={initialSettings}
        dateValidation={'w-150px'}
        dateValue={dateShow}
      />
    )
  }

  const fetchFirstRoute = async () => {
    setFetchingFirstRoute(true)

    const url = `${apiBaseURL()}routes/drop-down?ordering=name&limit=2`

    const response = await getRequest(url, true)

    if (response?.results?.length > 0) {
      const data = response?.results[0]
      let concatName = data?.name ?? ''

      if (!isEmpty(data?.sales_team_name)) {
        concatName = (
          <span>
            {data.name} - <b>{data?.sales_team_name}</b>
          </span>
        )
      }

      setRoute({
        label: concatName,
        value: data?.id ?? '',
      })
    }

    setFetchingFirstRoute(false)
  }

  useEffect(() => {
    fetchFirstRoute()
  }, [])

  const CustomDatePickerSingle = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let newDate = dateFormat(start._d)
          setDateShowSingle({
            ['dateApi']: newDate.apiDate,
            ['dateView']: newDate.displayDate,
            ['setDateShowInput']: newDate.displayDate,
          })
          setInitialSettings((pre: any) => ({...pre, startDate: start._d}))
        }}
        initialSettings={initialSettings}
        dateValidation={clsx('w-150px', validation.date)}
        dateValue={dateShowSingle.setDateShowInput}
      />
    )
  }

  const handleUnit = (item: any) => {
    let itemUnit = ''

    if (item.fix_size) {
      itemUnit = item.material.name
    } else {
      itemUnit = item.measurement_unit
    }

    setUnit(measurementLabel(itemUnit))
  }

  const handleFuturePrice = (e: any) => {
    if (e.target.checked) {
      setFutureDate(true)
      setFilterStates({...filterStates, ['futureDate']: true})
      setButtonDisable(true)
    } else {
      setFutureDate(false)
      setFilterStates({...filterStates, ['futureDate']: false})
    }

    setListLoader(true)

    ManagePriceList(
      limit,
      currentOffset,
      search,
      filterBrand.value,
      filterCategory.value,
      filterGroups.value,
      e.target.checked,
      routeId
    ).then((res) => {
      setGetList(res.data)
      setCount(res.count)
      setListLoader(false)
    })
  }

  const valueFromMasterArray = (itemId: any, type: string) => {
    let value: any = ''

    if (masterArray.length > 0) {
      const index = masterArray.findIndex((master: any) => {
        return master && master.productId == itemId
      })

      if (index !== -1) {
        switch (type) {
          case 'wholesalePrice':
            value = masterArray[index] ? masterArray[index].wholesalePrice : ''
            break
          case 'wholesaleExpiry':
            value = masterArray[index] ? masterArray[index].wholesaleExpiry : ''
            break
          case 'retailPrice':
            value = masterArray[index] ? masterArray[index].retailPrice : ''
            break
          case 'retailExpiry':
            value = masterArray[index] ? masterArray[index].retailExpiry : ''
            break
          case 'reason_note':
            value = masterArray[index] ? masterArray[index].reason_note : ''
            break
        }
      }
    }

    return value
  }

  const handleChangeReset = () => {
    let updatedState: any = {...filterStates}

    updatedState['filterBrand'] = ''
    updatedState['filterCategory'] = ''
    updatedState['filterGroups'] = ''
    updatedState['futureDate'] = false
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''

    setFilterStates(updatedState)

    setSearch('')
    setFilterBrand({label: 'All Brands', value: ''})
    setFilterCategory({label: 'All Categories', value: ''})
    setFilterGroups({label: 'All Product Groups', value: ''})
    setCurrentOffset(0)
    setSelected(0)
    setFutureDate(false)
    setButtonDisable(false)
  }

  return (
    <>
      <EATitle title='Manage Price' />
      <div>
        <PageTitle breadcrumbs={[priceHeaderTitle]}>
          {intl.formatMessage({id: 'Manage Price'})}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          message={
            messageHandler === 'successEdit'
              ? FormatMessage('MANAGE.PRICE.UPDATE', messageTitle)
              : FormatMessage('MANAGE.PRICE.UPDATE.BULK')
          }
        />

        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='Something went wrong'
        />

        <div className='card mb-4 mb-xl-5'>
          <div className='card-body pt-1 pb-0'>
            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap cursor-pointer'>
                <li
                  className='nav-item'
                  onClick={() => {
                    setType('warehouse')
                  }}
                >
                  <div
                    className={`nav-link text-active-primary me-0 ${
                      type === 'warehouse' ? 'active' : ''
                    }`}
                  >
                    {getLoginWarehouse().label || ''}
                  </div>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    setType('route')
                  }}
                >
                  <div
                    className={`nav-link text-active-primary me-0 ${
                      type === 'route' ? 'active' : ''
                    }`}
                  >
                    Routes
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`card ${className}`}>
          {isRouteTab ? (
            fetchingFirstRoute || listLoader ? (
              <IsLoader />
            ) : (
              <></>
            )
          ) : (
            listLoader && <IsLoader />
          )}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={searchList} value={search} isDisable={listLoader} />
            <div className='ms-auto'>
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'>
                  <input
                    className='form-check-input widget-13-check me-2'
                    type='checkbox'
                    value={futureDate}
                    id='allrequestList'
                    checked={futureDate}
                    name='all'
                    onChange={handleFuturePrice}
                  />
                  <label className='fw-500' htmlFor='allrequestList'>
                    Show Future Price
                  </label>
                </div>

                {isRouteTab && (
                  <AsyncPaginate
                    loadOptions={fetchRoute}
                    isSearchable
                    className='react-select-container my-1 me-3 w-220px'
                    classNamePrefix='react-select'
                    onChange={(val: any) => filterSearchRoute(val, 'LIST')}
                    isDisabled={listLoader}
                    placeholder='Select'
                    value={route}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}

                <AsyncPaginate
                  loadOptions={fetchBrand}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  onChange={(val: any) => filterSearchBrand(val, 'LIST')}
                  isDisabled={listLoader}
                  placeholder='All Brands'
                  value={filterBrand}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  styles={categoryStyle}
                  onChange={(val: any) => filterSearchCategory(val, 'LIST')}
                  isDisabled={listLoader}
                  placeholder='All Categories'
                  value={filterCategory}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchProductsGroups}
                  isSearchable
                  className='react-select-container my-1 me-3 w-170px'
                  classNamePrefix='react-select'
                  onChange={(val: any) => filterSearchGroups(val, 'LIST')}
                  isDisabled={listLoader}
                  placeholder='All Product Groups'
                  value={filterGroups}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write && (
                  <div className='me-3'>
                    <EaButton
                      onClick={() => {
                        setMessageHandler('successBulkEdit')
                        setGroupsOffset('')
                        setCategoryOffset('')
                        setBrandInOffset('')
                        setGroupsOption('')
                        setBrandOption('')
                        setCategoryOption('')
                        setIsModalDetailsFetching(true)

                        ManageAllPriceList(
                          brandValueModal,
                          categoryValueModal,
                          groupsValueModal,
                          futureDate,
                          routeId
                        ).then((res) => {
                          setGetListNodal(res)
                          if (res.length == 0) {
                            setLengthModal('2')
                          } else {
                            setLengthModal('1')
                          }

                          setIsModalDetailsFetching(false)
                        })
                      }}
                      isModal={true}
                      dataTarget='#ea_modal_edit_product'
                      btnName='Bulk Edit'
                    />
                  </div>
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
              </form>
            </div>
          </div>
          <div className='card-body py-4'>
            <div className='table-responsive'>
              {getList.length === 0 ? (
                <NoRecords />
              ) : (
                <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-150px'>Product SKUs</th>
                      <th className='min-w-140px'>Effective From Date</th>
                      <th className='min-w-120px'>Wholesale Price</th>
                      <th className='min-w-120px'>Retail Price</th>
                      <th className='min-w-80px action-th py-2'>{permission.write && 'Action'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getList.map((item: any) => (
                      <tr>
                        <SkuThumbnail skuName={item.name} src={item.product_thumbnail} />
                        <td>
                          {item.product_sku_price.length !== 0
                            ? moment(item.product_sku_price[0].effective_date).format(
                                GLOBAL_DATE_FORMAT
                              )
                            : '-'}
                        </td>
                        <td>
                          {item.product_sku_price.length !== 0
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${
                                numberFormat(item.product_sku_price[0].wholesale_price)
                                  .displayFormat
                              }`
                            : '-'}
                        </td>
                        <td>
                          {item.product_sku_price.length !== 0
                            ? `${tenantConfiguration.currencyCode}` +
                              ' ' +
                              `${
                                numberFormat(item.product_sku_price[0].retail_price).displayFormat
                              }`
                            : '-'}
                        </td>
                        <td className='text-end'>
                          {permission.write && (
                            <a
                              href='#'
                              data-bs-toggle='modal'
                              data-bs-target='#ea_modal_price_edit'
                              onClick={() => {
                                handleUnit(item)
                                setMessageTitle(item.name)
                                setMessageHandler('successEdit')
                                let dateNew: any =
                                  item.product_sku_price.length !== 0 &&
                                  dateFormat(item.product_sku_price[0].effective_date)
                                item.product_sku_price.length !== 0 &&
                                  setInitialSettings((pre: any) => ({
                                    ...pre,
                                    startDate: dateNew.displayDate,
                                  }))
                                let value =
                                  item.product_sku_price.length !== 0
                                    ? item.product_sku_price[0]
                                    : ''
                                setDateShowSingle({
                                  ['dateApi']:
                                    item.product_sku_price.length !== 0
                                      ? moment(item.product_sku_price[0].effective_date).format(
                                          'YYYY-MM-DD'
                                        )
                                      : moment(new Date()).format('YYYY-MM-DD'),
                                  ['dateView']:
                                    item.product_sku_price.length !== 0 ? new Date() : new Date(),
                                  ['setDateShowInput']:
                                    item.product_sku_price.length !== 0
                                      ? moment(item.product_sku_price[0].effective_date).format(
                                          GLOBAL_DATE_FORMAT
                                        )
                                      : 'Select Date',
                                })

                                let checkDisable = true

                                if (isRouteTab && !isEmpty(value)) {
                                  const {
                                    wholesale_price,
                                    wholesale_expiry_price,
                                    retail_price,
                                    retail_expiry_price,
                                  } = value

                                  const isDisable = !(
                                    item?.default_wholesale_price > wholesale_price ||
                                    item?.default_wholesale_expiry_price > wholesale_expiry_price ||
                                    item?.default_retail_price > retail_price ||
                                    item?.default_retail_expiry_price > retail_expiry_price
                                  )

                                  checkDisable = isDisable
                                }

                                setPriceValue({
                                  ['priceItem']: value,
                                  ['productId']: item.id,
                                  ['name']: item.name,
                                  ['wholesalePriceSingle']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].wholesale_price
                                      : '',
                                  ['wholesaleExpirySingle']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].wholesale_expiry_price
                                      : '',
                                  ['retailPriceSingle']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].retail_price
                                      : '',
                                  ['retailExpirySingle']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].retail_expiry_price
                                      : '',
                                  ['effectiveDate']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].effective_date
                                      : '',
                                  ['reason_note']:
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].reason_note
                                      : '',
                                  ['disableReasonNote']: checkDisable,
                                  ['is_default_price_set']: item?.is_default_price_set,
                                  ['default_wholesale_price']: isEmpty(
                                    item?.default_wholesale_price
                                  )
                                    ? 0
                                    : item?.default_wholesale_price,
                                  ['default_wholesale_expiry_price']: isEmpty(
                                    item?.default_wholesale_expiry_price
                                  )
                                    ? 0
                                    : item?.default_wholesale_expiry_price,
                                  ['default_retail_price']: isEmpty(item?.default_retail_price)
                                    ? 0
                                    : item?.default_retail_price,
                                  ['default_retail_expiry_price']: isEmpty(
                                    item?.default_retail_expiry_price
                                  )
                                    ? 0
                                    : item?.default_retail_expiry_price,
                                })
                              }}
                              className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='ic mr-0'
                              />
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <CustomPaginate
          data={getList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_manage_price}
          filterStates={filterStates}
          isDisabled={listLoader}
        />

        {/* BULK UPDATE */}
        <div
          className='modal fade'
          id='ea_modal_edit_product'
          aria-hidden='true'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
        >
          <div className='modal-dialog modal-dialog-centered mw-470px modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header py-2'>
                <div>
                  <h2 className='modal-title fw-bold'>Update Price</h2>
                  {isRouteTab && <p>For {route?.label}</p>}
                </div>
                <div className='ms-auto d-flex align-items-center'>
                  <div className='d-flex'>
                    <AsyncPaginate
                      loadOptions={fetchBrand}
                      isSearchable
                      className='react-select-container my-1 me-3 w-120px'
                      classNamePrefix='react-select'
                      onChange={(val: any) => filterSearchBrand(val, 'MODAL')}
                      placeholder='All Brands'
                      value={filterBrandModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                    <AsyncPaginate
                      loadOptions={fetchCategory}
                      isSearchable
                      className='react-select-container my-1 me-3 w-160px'
                      classNamePrefix='react-select'
                      styles={categoryStyle}
                      onChange={(val: any) => filterSearchCategory(val, 'MODAL')}
                      placeholder='All Categories'
                      value={filterCategoryModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                    <AsyncPaginate
                      loadOptions={fetchProductsGroups}
                      isSearchable
                      className='react-select-container my-1 me-3 w-170px'
                      classNamePrefix='react-select'
                      onChange={(val: any) => filterSearchGroups(val, 'MODAL')}
                      placeholder='All Product Groups'
                      value={filterGroupsModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div
                    onClick={clearFunction}
                    className='btn p-1 mh-24 btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                  </div>
                </div>
              </div>
              <div className='modal-body py-3'>
                {isModalDetailsFetching ? (
                  <FetchingRecords />
                ) : (
                  <>
                    {getLengthModal !== '1' ? (
                      <div className='mt-5'>
                        <NoRecords />
                      </div>
                    ) : (
                      <table
                        id='tableId'
                        className='table table-borderless gx-2 table-row-gray-100 align-middle gs-0 gy-3'
                      >
                        <thead>
                          <tr className='text-muted fw-500'>
                            <th className='min-w-250px' style={{width: isRouteTab ? '32%' : '42%'}}>
                              Product SKUs
                            </th>
                            <th className='min-w-150px w-auto'>Wholesale Price</th>
                            <th className='min-w-150px w-auto'>Near Expiry</th>
                            <th className='min-w-150px w-auto'>Retail Price</th>
                            <th className='min-w-150px w-auto'>Near Expiry</th>
                            {isRouteTab && (
                              <th className='min-w-170px w-auto d-flex align-items-center'>
                                <div>Reason</div>
                                <InfoIcon message='Please provide a reason if the price is lower than the warehouse price.' />
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {getListModal.map((item: any, index: number) => {
                            let itemUnit = ''
                            let isReasonDisable = false

                            if (item.fix_size) {
                              itemUnit = item.material.name
                            } else {
                              itemUnit = item.measurement_unit
                            }

                            if (isRouteTab) {
                              const {
                                default_wholesale_price,
                                default_wholesale_expiry_price,
                                default_retail_price,
                                default_retail_expiry_price,
                              } = item

                              const checkDisable = !(
                                default_wholesale_price >
                                  Number(
                                    masterArray.length > 0
                                      ? valueFromMasterArray(item.id, 'wholesalePrice')
                                      : 0
                                  ) ||
                                default_wholesale_expiry_price >
                                  Number(
                                    masterArray.length > 0
                                      ? valueFromMasterArray(item.id, 'wholesaleExpiry')
                                      : 0
                                  ) ||
                                default_retail_price >
                                  Number(
                                    masterArray.length > 0
                                      ? valueFromMasterArray(item.id, 'retailPrice')
                                      : 0
                                  ) ||
                                default_retail_expiry_price >
                                  Number(
                                    masterArray.length > 0
                                      ? valueFromMasterArray(item.id, 'retailExpiry')
                                      : 0
                                  )
                              )

                              isReasonDisable = checkDisable
                            }

                            return (
                              <tr>
                                <td>
                                  <input
                                    type='text'
                                    className='form-control '
                                    value={item.name}
                                    disabled
                                  />
                                  {isRouteTab && (
                                    <div className='text-primary'>
                                      {getLoginWarehouse().label || ''}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div
                                    className={clsx(
                                      'input-group',
                                      bulkValidationWholesale[index] &&
                                        bulkValidationWholesale[index].borderValidation,
                                      '-grp'
                                    )}
                                  >
                                    <span className='input-group-text bg-white border-end-0'>
                                      {tenantConfiguration.currencyCode}
                                    </span>
                                    <input
                                      type='text'
                                      name='wholesalePrice'
                                      onWheel={(e: any) => e.target.blur()}
                                      disabled={isRouteTab && !item.is_default_price_set}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          if (e.target.value !== '0') {
                                            getBulkValue(e, item, index)
                                          }
                                        }
                                      }}
                                      id={
                                        item.product_sku_price.length === 0
                                          ? item.id
                                          : item.product_sku_price[0].id
                                      }
                                      className={clsx(
                                        'form-control border-start-0 ps-0 border-focus-light',
                                        bulkValidationWholesale[index] &&
                                          bulkValidationWholesale[index].validation
                                      )}
                                      value={
                                        masterArray.length > 0
                                          ? valueFromMasterArray(item.id, 'wholesalePrice')
                                          : ''
                                      }
                                      placeholder={
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].wholesale_price.toLocaleString(
                                              undefined,
                                              {maximumFractionDigits: 2}
                                            )
                                          : '0.00'
                                      }
                                    />
                                    <span className='input-group-text min-w-80px border-start ms-0'>
                                      /{measurementLabel(itemUnit)}
                                    </span>
                                  </div>

                                  {isRouteTab && (
                                    <div className='text-primary'>
                                      {
                                        numberFormatWithCode(
                                          isEmpty(item.default_wholesale_price)
                                            ? 0
                                            : item.default_wholesale_price
                                        ).displayFormat
                                      }
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div className='input-group'>
                                    <span className='input-group-text bg-white border-end-0'>
                                      {tenantConfiguration.currencyCode}
                                    </span>
                                    <input
                                      type='text'
                                      onWheel={(e: any) => e.target.blur()}
                                      name='wholesaleExpiry'
                                      disabled={isRouteTab && !item.is_default_price_set}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          if (e.target.value !== '0') {
                                            getBulkValue(e, item)
                                          }
                                        }
                                      }}
                                      id={
                                        item.product_sku_price.length === 0
                                          ? item.id
                                          : item.product_sku_price[0].id
                                      }
                                      className='form-control border-start-0 ps-0 border-focus-light'
                                      placeholder={
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].wholesale_expiry_price.toLocaleString(
                                              undefined,
                                              {maximumFractionDigits: 2}
                                            )
                                          : '0.00'
                                      }
                                      value={
                                        masterArray.length > 0
                                          ? valueFromMasterArray(item.id, 'wholesaleExpiry')
                                          : ''
                                      }
                                    />
                                    <span className='input-group-text min-w-80px border-start ms-0'>
                                      /{measurementLabel(itemUnit)}
                                    </span>
                                  </div>

                                  {isRouteTab && (
                                    <div className='text-primary'>
                                      {
                                        numberFormatWithCode(
                                          isEmpty(item.default_wholesale_expiry_price)
                                            ? 0
                                            : item.default_wholesale_expiry_price
                                        ).displayFormat
                                      }
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div
                                    className={clsx(
                                      'input-group',
                                      bulkValidationRetail[index] &&
                                        bulkValidationRetail[index].borderValidation,
                                      '-grp'
                                    )}
                                  >
                                    <span className='input-group-text bg-white border-end-0'>
                                      {tenantConfiguration.currencyCode}
                                    </span>
                                    <input
                                      type='text'
                                      name='retailPrice'
                                      onWheel={(e: any) => e.target.blur()}
                                      disabled={isRouteTab && !item.is_default_price_set}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          if (e.target.value !== '0') {
                                            getBulkValue(e, item, index)
                                          }
                                        }
                                      }}
                                      id={
                                        item.product_sku_price.length === 0
                                          ? item.id
                                          : item.product_sku_price[0].id
                                      }
                                      className={clsx(
                                        'form-control border-start-0 ps-0 border-focus-light',
                                        bulkValidationRetail[index] &&
                                          bulkValidationRetail[index].validation
                                      )}
                                      placeholder={
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].retail_price.toLocaleString(
                                              undefined,
                                              {maximumFractionDigits: 2}
                                            )
                                          : '0.00'
                                      }
                                      value={
                                        masterArray.length > 0
                                          ? valueFromMasterArray(item.id, 'retailPrice')
                                          : ''
                                      }
                                    />
                                    <span className='input-group-text min-w-80px border-start ms-0'>
                                      /{measurementLabel(itemUnit)}
                                    </span>
                                  </div>

                                  {isRouteTab && (
                                    <div className='text-primary'>
                                      {
                                        numberFormatWithCode(
                                          isEmpty(item.default_retail_price)
                                            ? 0
                                            : item.default_retail_price
                                        ).displayFormat
                                      }
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <div className='input-group'>
                                    <span className='input-group-text bg-white border-end-0'>
                                      {tenantConfiguration.currencyCode}
                                    </span>
                                    <input
                                      type='text'
                                      name='retailExpiry'
                                      onWheel={(e: any) => e.target.blur()}
                                      disabled={isRouteTab && !item.is_default_price_set}
                                      value={
                                        masterArray.length > 0
                                          ? valueFromMasterArray(item.id, 'retailExpiry')
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          if (e.target.value !== '0') {
                                            getBulkValue(e, item)
                                          }
                                        }
                                      }}
                                      id={
                                        item.product_sku_price.length === 0
                                          ? item.id
                                          : item.product_sku_price[0].id
                                      }
                                      className='form-control border-start-0 ps-0 border-focus-light'
                                      placeholder={
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].retail_expiry_price.toLocaleString(
                                              undefined,
                                              {maximumFractionDigits: 2}
                                            )
                                          : '0.00'
                                      }
                                    />
                                    <span className='input-group-text min-w-80px border-start ms-0'>
                                      /{measurementLabel(itemUnit)}
                                    </span>
                                  </div>

                                  {isRouteTab && (
                                    <div className='text-primary'>
                                      {
                                        numberFormatWithCode(
                                          isEmpty(item.default_retail_expiry_price)
                                            ? 0
                                            : item.default_retail_expiry_price
                                        ).displayFormat
                                      }
                                    </div>
                                  )}
                                </td>

                                {isRouteTab && (
                                  <td>
                                    <input
                                      type='text'
                                      name='reason_note'
                                      disabled={isReasonDisable || !item.is_default_price_set}
                                      className={clsx(
                                        'form-control',
                                        bulkValidationReasonNote[index] &&
                                          bulkValidationReasonNote[index].validation
                                      )}
                                      placeholder={
                                        item.product_sku_price.length !== 0 &&
                                        !isEmpty(item.product_sku_price[0].reason_note)
                                          ? item.product_sku_price[0].reason_note
                                          : 'Type here'
                                      }
                                      onChange={(e) => getBulkValue(e, item)}
                                      id={
                                        item.product_sku_price.length === 0
                                          ? item.id
                                          : item.product_sku_price[0].id
                                      }
                                    />
                                    <div className='invisible'>one</div>
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
              <div className='modal-footer justify-content-end'>
                {!isEmpty(error) && (
                  <div className='my-2'>
                    <ErrorHandler errorMessage={error} />
                  </div>
                )}

                <span>Effective From Date</span>
                <CustomDatePicker />
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={clearFunction}
                  id='closeBulModal'
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={updateBulkPrice}
                  disabled={disableBtn}
                >
                  {isModalLoading}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* SINGLE UPDATE */}
        <div
          className='modal fade'
          id='ea_modal_price_edit'
          aria-hidden='true'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
        >
          <div
            className='modal-dialog modal-dialog-centered w-470px'
            style={{
              width: '80%',
              maxWidth: 'none',
              height: 400,
            }}
          >
            <div className='modal-content'>
              <div className='modal-header py-2 align-items-center'>
                <div className='pt-2'>
                  <h2 className='modal-title fw-bold'>Update Price</h2>
                  {isRouteTab && <p>For {route?.label}</p>}
                </div>
                <div
                  className='btn p-1 mh-24 btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={clearSingleModal}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                </div>
              </div>
              <div className='modal-body py-3 overflow-auto'>
                <table className='table table-borderless gx-2 table-row-gray-100 align-middle gs-0 gy-3 mb-1'>
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-250px' style={{width: isRouteTab ? '22%' : '25%'}}>
                        Product SKUs
                      </th>
                      <th className=''>Wholesale Price</th>
                      <th className=''>Near Expiry</th>
                      <th className=''>Retail Price</th>
                      <th className=''>Near Expiry</th>
                      {isRouteTab && (
                        <th className='min-w-150px w-auto d-flex align-items-center'>
                          <div>Reason</div>
                          <InfoIcon message='Please provide a reason if the price is lower than the warehouse price.' />
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type='text'
                          className={clsx('form-control')}
                          disabled
                          value={priceValue.name}
                        />

                        {isRouteTab && (
                          <div className='text-primary'>{getLoginWarehouse().label || ''}</div>
                        )}
                      </td>
                      <td>
                        <div
                          className={clsx(
                            'input-group',
                            validation.wholesalePriceSingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='text'
                            id='wholesalePriceSingle'
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                if (e.target.value !== '0') {
                                  const validation = ValidationHandler(e.target.value, 'is-invalid')

                                  singleBulkChange(e)

                                  setValidation((preValue: any) => ({
                                    ...preValue,
                                    wholesalePriceSingle: validation,
                                  }))
                                }
                              }
                            }}
                            disabled={isRouteTab && !priceValue?.is_default_price_set}
                            onBlur={() => checkReasonNote()}
                            value={priceValue.wholesalePriceSingle}
                            placeholder='0.00'
                            name='wholesalePriceSingle'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              validation.wholesalePriceSingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>
                        {isRouteTab && (
                          <div className='text-primary'>
                            {
                              numberFormatWithCode(
                                isEmpty(priceValue?.default_wholesale_price)
                                  ? 0
                                  : priceValue?.default_wholesale_price
                              ).displayFormat
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          className={clsx(
                            'input-group',
                            validation.wholesaleExpirySingle === 'is-invalid'
                              ? 'is-invalid-grp'
                              : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='text'
                            onWheel={(e: any) => e.target.blur()}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                if (e.target.value !== '0') {
                                  singleBulkChange(e)
                                }
                              }
                            }}
                            onBlur={(e) => {
                              handleBlur(e)
                              checkReasonNote()
                            }}
                            value={priceValue.wholesaleExpirySingle}
                            disabled={isRouteTab && !priceValue?.is_default_price_set}
                            name='wholesaleExpirySingle'
                            placeholder='0.00'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              ''
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>

                        {isRouteTab && (
                          <div className='text-primary'>
                            {
                              numberFormatWithCode(
                                isEmpty(priceValue?.default_wholesale_expiry_price)
                                  ? 0
                                  : priceValue?.default_wholesale_expiry_price
                              ).displayFormat
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          className={clsx(
                            'input-group',
                            validation.retailPriceSingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='text'
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                if (e.target.value !== '0') {
                                  singleBulkChange(e)
                                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                                  setValidation((preValue: any) => ({
                                    ...preValue,
                                    retailPriceSingle: validation,
                                  }))
                                }
                              }
                            }}
                            value={priceValue.retailPriceSingle}
                            disabled={isRouteTab && !priceValue?.is_default_price_set}
                            onBlur={() => checkReasonNote()}
                            name='retailPriceSingle'
                            placeholder='0.00'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              validation.retailPriceSingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>

                        {isRouteTab && (
                          <div className='text-primary'>
                            {
                              numberFormatWithCode(
                                isEmpty(priceValue?.default_retail_price)
                                  ? 0
                                  : priceValue?.default_retail_price
                              ).displayFormat
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          className={clsx(
                            'input-group',
                            validation.retailExpirySingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='text'
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/
                              if (e.target.value === '' || re.test(e.target.value)) {
                                if (e.target.value !== '0') {
                                  singleBulkChange(e)
                                  const validation = ValidationHandler(e.target.value, 'is-invalid')
                                  setValidation((preValue: any) => ({
                                    ...preValue,
                                    retailPriceSingle: validation,
                                  }))
                                }
                              }
                            }}
                            onWheel={(e: any) => e.target.blur()}
                            onBlur={(e) => {
                              handleBlur(e)
                              checkReasonNote()
                            }}
                            value={priceValue.retailExpirySingle}
                            disabled={isRouteTab && !priceValue?.is_default_price_set}
                            placeholder='0.00'
                            name='retailExpirySingle'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              ''
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>

                        {isRouteTab && (
                          <div className='text-primary'>
                            {
                              numberFormatWithCode(
                                isEmpty(priceValue?.default_retail_expiry_price)
                                  ? 0
                                  : priceValue?.default_retail_expiry_price
                              ).displayFormat
                            }
                          </div>
                        )}
                      </td>

                      {isRouteTab && (
                        <td>
                          <input
                            type='text'
                            value={priceValue.reason_note}
                            placeholder='Type here'
                            name='reason_note'
                            onChange={singleBulkChange}
                            disabled={
                              priceValue?.disableReasonNote || !priceValue?.is_default_price_set
                            }
                            className={clsx('form-control', validation.reason_note)}
                          />

                          <div className='invisible'>invisible</div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>

                <div className='my-2'>
                  <ErrorHandler internalServerError={true} />
                </div>
              </div>

              <div className='modal-footer justify-content-end'>
                <span>Effective From Date</span>
                <CustomDatePickerSingle />
                <button
                  type='button'
                  onClick={clearSingleModal}
                  id='closeSingleModal'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                >
                  {'Cancel'}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={updateSinglePrice}
                  disabled={disableBtn}
                >
                  {isModalLoading}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManagePrice
