import {
  checkEmpty,
  dateFormat,
  durationToMinutes,
  isEmpty,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

const Card = ({data, setOrderId, kms, highLightCard}: any) => {
  const {
    id,
    manual_seq,
    customer_name,
    street_name,
    ward_name,
    delivery_start_time,
    delivery_end_time,
    delivery_duration,
    customer_visit_status,
    orders = [],
    credit_issues = [],
    preferences,
  } = data

  let totalMinutes = 0

  let startTime: any = isEmpty(delivery_start_time) ? '' : new Date(delivery_start_time)
  let endTime: any = isEmpty(delivery_end_time) ? '' : new Date(delivery_end_time)

  // Calculate the difference in milliseconds, then convert to minutes
  if (!isEmpty(startTime) && !isEmpty(endTime)) {
    const differenceInMs = endTime.getTime() - startTime.getTime()

    totalMinutes = Math.floor(differenceInMs / (1000 * 60)) + 1 // Convert milliseconds to minutes
  }

  return (
    <div className='' id={`sr_drop_point_${manual_seq}`}>
      <div
        className='rounded p-3 d-flex'
        style={{
          height: 70,
          alignItems: 'center',
        }}
      >
        <div
          className='timeline d-flex flex-column align-items-center me-3'
          style={{
            height: 70,
          }}
        >
          <div
            className={`${
              customer_visit_status === 'completed' ? 'bg-success' : 'bg-gray-500'
            } time-line`}
          ></div>

          <div
            className={`step-indicator fw-bold p-2 rounded text-center w-40px text-white ${
              customer_visit_status === 'completed' ? 'bg-success' : 'bg-gray-500'
            }`}
          >
            <div>{checkEmpty(manual_seq)}</div>
          </div>

          <div
            className={`${
              customer_visit_status === 'completed' ? 'bg-success' : 'bg-gray-500'
            } time-line`}
          ></div>
        </div>

        <div>
          <div className='distance-time fw-bold'>{kms} kms</div>
        </div>

        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          {customer_visit_status === 'yet_to_visit' && (
            <span className={`badge bg-secondary text-dark fw-bold`}>Yet To Visit</span>
          )}

          {customer_visit_status === 'visited' && (
            <span className={`badge bg-secondary text-dark fw-bold`}>Visited</span>
          )}

          {customer_visit_status === 'in_transit' && (
            <span className={`badge bg-secondary text-dark fw-bold`}>In Transit</span>
          )}

          {customer_visit_status === 'completed' && (
            <span className={`badge bg-success text-white fw-bold`}>Completed</span>
          )}
        </div>
      </div>

      <div
        className={`rounded p-3 d-flex drop-point-hover-card ${
          highLightCard == manual_seq ? 'highlight-drop-point-card' : ''
        }`}
      >
        {/* Timeline column */}

        <div className='timeline d-flex flex-column align-items-center me-3'>
          <div className=''>
            {isEmpty(delivery_start_time) ? '-' : dateFormat(delivery_start_time, 'LT').displayDate}
          </div>

          <div className='time-line'></div>

          <div className='time-circle bg-secondary fw-bold p-1'>
            {isEmpty(delivery_duration) ? (
              '-'
            ) : (
              <div>
                <div>{durationToMinutes(delivery_duration)}</div>
                <div>min</div>
              </div>
            )}
          </div>

          <div className='time-line'></div>

          <div className=''>
            {isEmpty(delivery_end_time) ? '-' : dateFormat(delivery_end_time, 'LT').displayDate}
          </div>
        </div>

        {/* Card content column */}
        <div
          className='flex-grow-1 cursor-pointer w-100px'
          id='kt_drawer_chat_toggle'
          onClick={() => {
            setOrderId && setOrderId(id)
          }}
        >
          <div className='d-flex justify-content-between align-content-center'>
            <div>
              <h5 className='fw-bold mb-1'>{customer_name}</h5>
              <div className='text-muted mb-2'>
                {street_name} , {ward_name}
              </div>
            </div>

            {/* <div>
              <KTSVG path='/media/icons/duotune/general/gen018.svg' className='me-1' />
            </div> */}
          </div>

          {orders.length > 0 && (
            <>
              <hr className='mb-2' />
              <div className='d-flex justify-content-between'>
                <div className='mt-2'>
                  <strong>Pre-Order</strong>
                </div>
                <div className='mt-2'>
                  (
                  {orders.filter(
                    (i: any) =>
                      i?.order_status === 'completed' && !i?.is_rejected && !i?.is_auto_cancelled
                  )?.length || 0}
                  /{orders.length})
                </div>
              </div>

              <hr className='mb-2' />

              <div className='d-flex justify-content-between'>
                <div className='text-primary font-bold'>Order ID</div>
                <div className='text-primary font-bold'>Order Amount</div>
              </div>
              {orders.map((order: any, index: any) => (
                <div key={index} className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <span>{isEmpty(order?.order_no) ? '-' : `#${order?.order_no}`}</span>

                    {order?.order_status === 'completed' &&
                    !order?.is_rejected &&
                    !order?.is_auto_cancelled ? (
                      <i className='bi bi-check text-success font-24'></i>
                    ) : (
                      (order?.is_rejected === true || order?.is_auto_cancelled === true) && (
                        <i className='bi bi-x-lg text-danger'></i>
                      )
                    )}
                  </div>
                  <div className='d-flex align-items-center'>
                    <span>
                      {isEmpty(order?.total_payable)
                        ? '-'
                        : numberFormatWithCode(order?.total_payable).displayFormat}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}

          {preferences?.product_groups?.length > 0 && (
            <>
              <hr className='mb-2' />

              <div className='text-primary mt-5 font-bold'>Preferred Product Group</div>

              {preferences?.product_groups?.length > 0 &&
                preferences?.product_groups.map((i: any, index: any) => {
                  return (
                    <>
                      {index !== 0 ? ',' : ''} {i?.name}
                    </>
                  )
                })}
            </>
          )}

          {credit_issues && credit_issues.length > 0 && (
            <>
              <hr className='mb-2' />
              <div className='d-flex justify-content-between'>
                <div className='mt-2'>
                  <strong>Credit Issue</strong>
                </div>
                <div className='mt-2'>
                  ({credit_issues.filter((i: any) => i?.status === 'completed')?.length || 0}/
                  {credit_issues.length})
                </div>
              </div>

              <hr className='mb-2' />

              <div className='d-flex justify-content-between'>
                <div className='text-primary fw-bold'>Credit Issue ID</div>
                <div className='text-primary fw-bold'>Credit Issue Amount</div>
              </div>
              {credit_issues.map((issue: any, index: any) => (
                <div key={index} className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <span>{isEmpty(issue?.id) ? '-' : `#${issue?.id}`}</span>

                    {issue?.status === 'completed' && (
                      <i className='bi bi-check text-success font-24'></i>
                    )}

                    {issue?.status === 'rejected' && (
                      <i className='bi bi-x-lg text-danger font-24'></i>
                    )}
                  </div>

                  <div className='d-flex align-items-center'>
                    <span>
                      {isEmpty(issue?.approved_amount)
                        ? '-'
                        : numberFormatWithCode(issue?.approved_amount).displayFormat}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}

          {credit_issues.length === 0 &&
            preferences?.product_groups?.length === 0 &&
            orders.length === 0 && (
              <div className='d-flex justify-content-start pt-5 mt-3 ps-2'>
                <NoRecords />
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default Card
