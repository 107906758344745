import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps'
import {useEffect, useState} from 'react'
import tenantConfiguration from '../../../../../TenantVariables'
import {focusVatidateField, isEmpty} from '../../../../../_eaFruitsDms/helpers'

const RouteWiseCustomerMap = withScriptjs(
  withGoogleMap((props: any) => {
    const [mapRef, setMapRef] = useState<any>(null)

    useEffect(() => {
      if (mapRef && props?.customerLocation?.length > 0) {
        const bounds = new google.maps.LatLngBounds()
        props.customerLocation.forEach((location: any) => {
          bounds.extend(new google.maps.LatLng(location.latitude, location.longitude))
        })
        mapRef.fitBounds(bounds)
      }
    }, [mapRef, props?.customerLocation])

    return (
      <GoogleMap ref={(map: any) => setMapRef(map)}>
        {props?.warehouseLocation && (
          <Marker
            position={props?.warehouseLocation}
            icon={{
              url: tenantConfiguration.imageBaseUrl + 'warehousepin.png',
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(50, 50),
            }}
          />
        )}

        {props.customerLocation &&
          props.customerLocation.map((i: any, index: any) => {
            return (
              <Marker
                key={`${i.latitude}-${i.longitude}-${index}-${i?.manual_seq}`} // Using a unique key
                position={{lat: i.latitude, lng: i.longitude}}
                label={{
                  text: isEmpty(i?.manual_seq) ? '-' : i?.manual_seq,
                  color: 'black',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  focusVatidateField(`sr_drop_point_${i?.manual_seq}`)
                  props?.setHighLightCard && props?.setHighLightCard(i?.manual_seq)
                }}
                icon={{
                  url: `${tenantConfiguration.imageBaseUrl}${
                    i?.status === 'visited' ? 'ic-map.svg' : 'ic-map-1.svg'
                  }`,
                  anchor: new google.maps.Point(17, 46),
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            )
          })}
      </GoogleMap>
    )
  })
)

export default RouteWiseCustomerMap
