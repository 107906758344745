import {apiBaseURL} from '../../helpers'
import {deleteRequest, getRequest, postRequest, putRequest} from '../../https/apiCall'
import {getW_Id} from '../../localStorage/getItem/getLocalStorage'

export const bulkPriceCreateUpdate = async (param: any) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  await postRequest(`${apiBaseURL()}pricing/bulk_price_create_update`, param, true).then((res) => {
    if (res.status === 201) {
      notify.success = true
    }

    if (res.status === 500 || res.status === 400) {
      notify.failed = true

      if (res?.data?.reason_not_found) {
        notify.errorField = 'reason_not_found'
      }

      if (res?.data?.default_price_not_found) {
        notify.errorField = 'default_price_not_found'
      }
    }
  })

  return notify
}

//request method for api implementation :- last change by kriti

export const ManagePriceList = async (
  limit: any,
  offset: any,
  val?: any,
  brandId?: any,
  categoryId?: any,
  productGroup?: any,
  show_future_price?: any,
  route_id?: any
) => {
  const data = {
    data: '',
    count: '',
    next: '',
    prev: '',
  }
  await getRequest(
    // `${apiBaseURL()}pricing/productprice/?warehouse_id=${getW_Id}&limit=${limit}&offset=${offset}&ordering=name&search=${val}&brand_id=${brandId}&category_id__id=${categoryId}&variants__group_name__id=${productGroup}&show_future_price=${show_future_price}`,
    `${apiBaseURL()}pricing/productprice/?warehouse_id=${getW_Id}&limit=${limit}&offset=${offset}&ordering=&search=${val}&brand_id=${brandId}&category_id__id=${categoryId}&variants__group_name__id=${productGroup}&show_future_price=${show_future_price}&route_id=${
      route_id ? route_id : ''
    }`,
    true
  )
    .then((res) => {
      data.count = res.count
      data.next = res.next
      data.prev = res.previous
      data.data = res.results
    })
    .catch((err) => {
      console.log('ManagePriceList Error ==>>', err.response)
    })
  return data
}

//request method for api implementation :- last change by kriti
export const ManageAllPriceList = async (
  brandId?: any,
  categoryId?: any,
  productGroup?: any,
  show_future_price?: any,
  route_id?: any
) => {
  let data = ''
  await getRequest(
    // `${apiBaseURL()}pricing/productprice/?warehouse_id=${getW_Id}&ordering=name&brand_id=${brandId}&category_id__id=${categoryId}&variants__group_name__id=${productGroup}&limit=10000&show_future_price=${show_future_price}`,
    `${apiBaseURL()}pricing/productprice/?warehouse_id=${getW_Id}&ordering=&brand_id=${brandId}&category_id__id=${categoryId}&variants__group_name__id=${productGroup}&limit=10000&show_future_price=${show_future_price}&route_id=${
      route_id ? route_id : ''
    }`,
    true
  )
    .then((res) => {
      data = res.results
    })
    .catch((err) => {
      console.log('manage price all list Error ==>>', err.response.status)
    })
  return data
}

export const PriceSingle = async (id: string) => {
  let data = ''
  await getRequest(`${apiBaseURL()}master${id}/`, true)
    .then((res) => {
      data = res.data
    })
    .catch((err) => {
      console.log('singelPrice Error ==>>', err.response.status)
    })
  return data
}

//request method for api implementation :- last change by kriti
export const PriceUpdate = async (name: string, group_name: string, id: string) => {
  const notify = {
    success: false,
    failed: false,
    errorField: '',
  }
  const data = {
    name: name,
    group_name: group_name,
  }
  await putRequest(`${apiBaseURL()}master/product/variant/${id}/`, true, data)
    .then((res) => {
      notify.success = true // res
      console.log(res.data)
    })
    .catch((err) => {
      notify.failed = true // catch
      notify.errorField = err.response.data.error.field // catch
      console.log('price update Error ==>>', err.response.data)
    })
  return notify
}

//request method for api implementation :- last change by kriti
export const PriceDelete = async (id: string) => {
  const notify = {
    success: false,
    failed: false,
  }
  await deleteRequest(`${apiBaseURL()}master/product/variant/${id}/`, true)
    .then((res) => {
      notify.success = true // res
    })
    .catch((err) => {
      notify.failed = true // catch
      console.log('pricedelete Error ==>>', err.response)
    })
  return notify
}
