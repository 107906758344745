import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {KTSVG, checkRoleName, checkUserType} from '../../../../helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import SaDashboardFilter from '../../../../../app/pages/dashboard/SaDashboardFilter'
import CreditRequestInfo from '../creditRequestInfo'
import SummaryFilter from '../../../../../app/pages/dashboard/SummaryFilter'
import InfoIcon from '../infoIcon'
import SupportRequestReportFilter from '../../../../../app/pages/dashboard/supportRequestReportFilter'
import {financeManager, logisticsManager} from '../../../../utils/roleNames'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs, backNavigate} = usePageData()
  const {config, classes} = useLayout()
  const navigate = useNavigate()
  const location = useLocation()
  const [routesUrl, setRoutesUrl] = useState<any>([])

  useEffect(() => {
    let routes = [
      // Super Admin
      '/warehouse/DC/singlewarehousename/overview',
      '/warehouse/DC/singlewarehousename/area-coverage',
      '/warehouse/DC/singlewarehousename/billing-info',
      '/warehouse/DC/singlewarehousename/team',
      '/warehouse/DC/singlewarehousename/stock',
      '/warehouse/DC/singlewarehousename/warehouse-customers',
      '/customers/suspended-customers/overview',
      '/customers/suspended-customers/business-details',
      '/customers/suspended-customers/business-address',
      '/customers/singlecustomer/credit-issue',
      '/customers/singlecustomer/overview',
      '/tele-sales/tele-sales-name/overview',
      '/tele-sales/tele-sales-name/survey-feedback',
      '/survey-forms/survey-feedback',
      '/survey-forms/survey-feedback-name',
      '/survey-forms/survey-detail',
      '/master/distribution-boundaries/regionname',
      '/cms/blogs/posts/add-post',
      '/cms/career/current-openings/add-openings',
      '/cms/career/application/application-details',
      '/cms/scuccess-stories/farmers/add-stories',
      '/cms/scuccess-stories/vendors/add-stories',
      '/cms/scuccess-stories/employees/add-stories',
      '/cms/testimonials/add-testimonials',
      '/cms/inquiries/contact/details',
      '/cms/inquiries/investors',
      '/cms/products/edit-products',
      '/credit-issue/credit-issues-detail',
      '/customers/singlecustomer/sales-order',
      '/customers/singlecustomer/complaints-request',
      '/warehouse/DC/singlewarehousename/complaint',
      '/tele-sales/complaint-title/overview',
      '/tele-sales/complaint-title/messages',
      '/tele-sales/tele-sales-name/complaints',
      '/tele-sales/tele-sales-name/sales-order-feedback',
      '/customers/suspended-customers/complaints-request',

      '/sales-invoices/sales-invoice-details',
      '/credit-issue/order-detail',
      // Warehouse Manager
      '/orders/order-detail',
      '/team-members/member-detail/overview',
      '/customers/singlecustomer/business-details',
      '/customers/singlecustomer/business-address',
      '/customers/manage-customer',
      '/customers/verification-requests/overview',
      '/customers/verification-requests/mobile-number',
      '/customers/verification-requests/business-details',
      '/customers/verification-requests/business-address',
      '/customers/suspended-customers/overview',
      '/customers/suspended-customers/business-details',
      '/customers/suspended-customers/business-address',
      '/price/discount-request/check-order-detail',
      '/reports/credit-issues/credit-issues-detail',
      '/reports/payment-issues/payment-issue-detail/transactions',
      '/reports/sales-invoice-report/sales-invoice-details',
      '/customers/order-quantity-requests/quantity-request-detail',
      '/reports/order-quantity-requests/quantity-request-detail',
      '/credit-issues/detail',
      '/customers/order-limit-access-request-details',

      // Operation Officer
      '/load-out-requests-from-SR',

      // Tele Sale
      '/warehouses/overview',
      '/warehouses/area-coverage',
      '/warehouses/team',
      '/warehouses/stock',
      '/complaint/new-message/complaint-detail/overview',
      '/complaint/my-assigned/complaint-detail/overview',
      '/complaint/my-closed/complaint-detail/overview',
      '/complaint/new-message/complaint-detail/messages',
      '/complaint/my-assigned/complaint-detail/messages',
      '/complaint/my-closed/complaint-detail/messages',

      '/survey-forms/add-survey-form',
      '/survey-forms/survey-feedback/survey-feedback-detail',

      // Account Manager
      '/sales-invoices/sales-invoice-detail',
      '/payment-issues/payment-issue-detail/transactions',
      '/payment-issues/payment-issue-detail/overview',
      '/credit-issues/credit-issues-detail',

      '/reports/credit-collection-report/credit-issues-detail',

      '/route/all-route-details',
      '/route/route-request-details',

      '/reports/sr-daily-routes',
    ]
    setRoutesUrl(routes)
  }, [])

  return (
    <>
      <div className='d-lg-flex'>
        {routesUrl.includes(location.pathname) && (
          <button
            onClick={() => {
              // navigate(backNavigate)
              navigate(-1)
            }}
            className='btn btn-icon py-0 text-hover-primary btn-sm me-3'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-1 me-0' />
          </button>
        )}
        <div
          id='kt_page_title'
          //  data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          //data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
        >
          {/* begin::Title */}
          {pageTitle && (
            <>
              <h1 className='d-flex align-items-center text-dark font-bold my-1 font-17'>
                {pageTitle}
                {pageDescription && config.pageTitle && config.pageTitle.description && (
                  <>
                    <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                    <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                  </>
                )}
              </h1>
            </>
          )}
          {/* end::Title */}

          {/* {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='v-seperator'></span>
            )}
            <ul className='breadcrumb breadcrumb-line font-13 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <>
                      <Link className='text-muted text-hover-primary' to={`${item.path}${index}`} >
                        {item.title}
                      </Link>
                      {item.subTitle &&
                        <span className='ms-3 me-3 b-seperator'></span>}

                      <span className='text-dark'>
                        {item.subTitle}
                      </span>
                    </>
                  ) : (
                    <span className='bullet bg-gray-200 w-12px h-2px'></span>
                  )}
                </li>
              ))}
            </ul>
          </>
        )} */}

          {pageBreadcrumbs &&
            pageBreadcrumbs.length > 0 &&
            config.pageTitle &&
            config.pageTitle.breadCrumbs && (
              <>
                {config.pageTitle.direction === 'row' && <span className='v-seperator'></span>}
                <ul className='breadcrumb breadcrumb-line font-13 my-1'>
                  {Array.from(pageBreadcrumbs).map((item, index) => (
                    <li
                      className={clsx('breadcrumb-item', {
                        'text-dark': !item.isSeparator && item.isActive,
                        'text-muted': !item.isSeparator && !item.isActive,
                      })}
                      key={`${item.path}${index}`}
                    >
                      {!item.isSeparator ? (
                        // <Link className='text-muted text-hover-primary' to={`${item.path}${index}`} >
                        <span> {item.title}</span>
                      ) : (
                        // </Link>

                        <span className='bullet bg-gray-200 w-5px h-2px'></span>
                      )}
                    </li>
                  ))}
                  <li className='breadcrumb-item text-dark'>{pageTitle}</li>
                </ul>
              </>
            )}
        </div>
      </div>
      {/* SA - dashboard filter */}
      {['/dashboard'].includes(location.pathname) &&
        checkUserType(['sa', 'wm', 'oo']) &&
        !checkRoleName([financeManager, logisticsManager]) && <SaDashboardFilter />}

      {/* support request report */}
      {['/reports/complaint-report'].includes(location.pathname) && <SupportRequestReportFilter />}

      {['/reports/summary-report'].includes(location.pathname) && <SummaryFilter />}

      {/* info icon for auto-approved label */}
      {(location.pathname.includes('/reports/credit-request-report') ||
        location.pathname.includes('/customers/credit-requests')) &&
        checkUserType('wm') && <CreditRequestInfo />}

      {checkRoleName(financeManager) && ['/dashboard'].includes(location.pathname) && (
        <div id='am-dashboard-filter'></div>
      )}
    </>
  )
}

export {DefaultTitle}
