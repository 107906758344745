import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {
  apiBaseURL,
  getDistanceFromLatLonInKm,
  googleApi,
  isEmpty,
} from '../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import Card from './card'
import RouteWiseCustomerMap from './routeWiseCustomerMap'
import './VerticalAlignment.css'

const DropPointSection = ({setOrderId, type}: any) => {
  const location: any = useLocation()
  const containerRef: any = useRef(null)

  const initialMeta = {
    start_latitude: 0,
    start_longitude: 0,
  }

  const [dropPoints, setDropPoints] = useState<any>([])
  const [dropPointsMeta, setDropPointsMeta] = useState<any>(initialMeta)
  const [loadingDropPoints, setLoadingDropPoints] = useState(true)
  const [customerLocation, setCustomerLocation] = useState<any>([])
  const [highLightCard, setHighLightCard] = useState<any>(null)

  const fetchRecords = async () => {
    setLoadingDropPoints(true)

    const routeId = location?.state?.itemId || ''

    const url = `${apiBaseURL()}routes/${routeId}/customers-route-history/list?pre_order=true&credit=true&customer=true&ordering=${type}`

    const response = await getRequest(url, true)

    if (response?.results?.length > 0) {
      const systemGeneratedData: any = []
      const customerLocation: any = []

      response?.results?.map((item: any) => {
        systemGeneratedData.push(item)
        customerLocation.push({
          latitude: Number(item?.latitude) ?? 0,
          longitude: Number(item?.longitude) ?? 0,
          manual_seq: isEmpty(item?.manual_seq) ? '-' : item?.manual_seq?.toString(),
          status: isEmpty(item?.customer_visit_status) ? '' : item?.customer_visit_status,
        })
      })

      setDropPointsMeta(response?.meta)
      setCustomerLocation(customerLocation)
      setDropPoints(systemGeneratedData)
    } else {
      setDropPointsMeta(initialMeta)
      setCustomerLocation([])
      setDropPoints([])
    }

    setLoadingDropPoints(false)
  }

  useEffect(() => {
    if (!isEmpty(location?.state?.itemId)) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0
      }

      fetchRecords()
    }
  }, [location, type])

  return (
    <>
      <div className='row pe-5'>
        {loadingDropPoints ? (
          <div className='col-md-12 mb-5 p-5 h-700px'>
            <FetchingRecords />
          </div>
        ) : dropPoints?.length === 0 ? (
          <div className='col-md-12 mb-5 p-5 h-700px'>
            {' '}
            <NoRecords />{' '}
          </div>
        ) : (
          <>
            <div className='col-md-6 mb-5 p-5 h-700px overflow-auto' ref={containerRef}>
              {dropPoints.map((data: any, index: any) => {
                let kms = 0 || ''

                const startLat =
                  index === 0 ? dropPointsMeta.start_latitude : dropPoints[index - 1].latitude
                const startLong =
                  index === 0 ? dropPointsMeta.start_longitude : dropPoints[index - 1].longitude

                const currentLat = data?.latitude
                const currentLong = data?.longitude

                kms = getDistanceFromLatLonInKm(startLat, startLong, currentLat, currentLong)

                return (
                  <Card
                    highLightCard={highLightCard}
                    setOrderId={setOrderId}
                    key={data?.id}
                    data={data}
                    kms={kms}
                  />
                )
              })}
            </div>

            <div className='col-md-6 mb-5 p-2'>
              {customerLocation.length > 0 && (
                <RouteWiseCustomerMap
                  googleMapURL={googleApi}
                  loadingElement={<div style={{height: `100%`}} />}
                  containerElement={<div style={{height: `100%`}} />}
                  mapElement={<div style={{height: `100%`}} />}
                  customerLocation={customerLocation}
                  isMarkerShown
                  setHighLightCard={setHighLightCard}
                  warehouseLocation={{
                    lat: parseFloat(dropPointsMeta?.start_latitude),
                    lng: parseFloat(dropPointsMeta?.start_longitude),
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default DropPointSection
