import React, {useEffect, useState} from 'react'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
} from '../../../../../_eaFruitsDms/helpers'
import {getRequest} from '../../../../../_eaFruitsDms/https/apiCall'
import {useLocation} from 'react-router-dom'
import NoRecords from '../../../../../_eaFruitsDms/layout/components/noRecords/noRecords'

const RouteDetails = () => {
  const location: any = useLocation()

  const [credit, setCredit] = useState<any>(null)
  const [customer, setCustomer] = useState<any>(null)
  const [dropPoint, setDropPoint] = useState<any>(null)
  const [order, setOrder] = useState<any>(null)

  const fetchRouteDetails = async () => {
    const date = location?.state?.routeDate
    const id = location?.state?.itemId

    // credit
    const urlCredit = `${apiBaseURL()}routes/${id}/credit?date=${dateFormat(date).apiDate}`
    const urlCreditResponse = await getRequest(urlCredit, true)

    urlCreditResponse && setCredit(urlCreditResponse)

    // customer
    const urlCustomer = `${apiBaseURL()}routes/${id}/customer-preference?date=${
      dateFormat(date).apiDate
    }`
    const urlCustomerResponse = await getRequest(urlCustomer, true)

    urlCustomerResponse && setCustomer(urlCustomerResponse)

    // drop-point
    const urlDropPoint = `${apiBaseURL()}routes/${id}/drop-points?date=${dateFormat(date).apiDate}`
    const urlDropPointResponse = await getRequest(urlDropPoint, true)

    urlDropPointResponse && setDropPoint(urlDropPointResponse)

    // order
    const urlOrder = `${apiBaseURL()}routes/${id}/order?date=${dateFormat(date).apiDate}`
    const urlOrderResponse = await getRequest(urlOrder, true)

    urlOrderResponse && setOrder(urlOrderResponse)
  }

  useEffect(() => {
    if (!isEmpty(location?.state?.routeDate) && !isEmpty(location?.state?.itemId)) {
      fetchRouteDetails()
    }
  }, [location])

  return (
    <>
      <div className='p-5'>
        <>
          <h5 className='fw-bold'> Drop Point</h5>

          <hr className='mb-0 bg-light opacity-100' />

          <div className='row mt-5 mb-3'>
            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Total Pre-Orders</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(order?.total_pre_orders)
                    ? '0'
                    : numberFormat(order?.total_pre_orders).displayFormat}{' '}
                  (From{' '}
                  {isEmpty(order?.total_pre_order_customer)
                    ? '0'
                    : numberFormat(order?.total_pre_order_customer).displayFormat}{' '}
                  customers)
                </span>
              </div>
            </div>
            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Total Credit Due</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(credit?.total_credit_due_amount)
                    ? '0'
                    : numberFormatWithCode(credit?.total_credit_due_amount).displayFormat}{' '}
                  From{' '}
                  {isEmpty(credit?.total_credit_due_customer)
                    ? '0'
                    : numberFormat(credit?.total_credit_due_customer).displayFormat}{' '}
                  Customers
                </span>
              </div>
            </div>
          </div>
        </>

        <>
          <div className='fw-bolder mt-3'>Customer Preferences</div>

          <hr className='mb-0 bg-light opacity-100' />

          <div className='row mt-3'>
            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'> Preferred Deliveries</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(customer?.total_customer_preferred_delivery)
                    ? '0'
                    : numberFormat(customer?.total_customer_preferred_delivery).displayFormat}
                </span>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-10 col-xl-10 mb-6'>
              <label className='font-14 text-muted mb-2'>
                Preferred Product Group (From Customers)
              </label>

              {!isEmpty(customer?.product_group_preferences) &&
              customer?.product_group_preferences?.length > 0 ? (
                <div className='row ps-3'>
                  {customer?.product_group_preferences.map((i: any) => {
                    return (
                      <div className='col-md-2 bg-secondary p-2 mb-2 me-2 rounded fw-bold'>
                        {checkEmpty(i?.product_group_name)}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='row ps-3'>
                  <NoRecords />
                </div>
              )}
            </div>
          </div>
        </>

        <>
          <h5 className='fw-bold mt-5'> Route Statics</h5>

          <hr className='mb-0 bg-light opacity-100' />

          <div className='row mt-5 mb-3'>
            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Travel Distance</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(dropPoint?.total_km)
                    ? '0'
                    : numberFormat(dropPoint?.total_km, true).displayFormat}{' '}
                  Kms.
                </span>
              </div>
            </div>

            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Pre-Orders/Successful Deliveries</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(dropPoint?.total_pre_orders)
                    ? '0'
                    : numberFormat(dropPoint?.total_pre_orders).displayFormat}
                  /
                  {isEmpty(dropPoint?.total_delivered_pre_orders)
                    ? '0'
                    : numberFormat(dropPoint?.total_delivered_pre_orders).displayFormat}
                </span>
              </div>
            </div>

            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Qty. Loaded/Sold</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(dropPoint?.approved_qty)
                    ? '0'
                    : numberFormat(dropPoint?.approved_qty).displayFormat}
                  /
                  {isEmpty(dropPoint?.deliverd_qty)
                    ? '0'
                    : numberFormat(dropPoint?.deliverd_qty).displayFormat}
                </span>
              </div>
            </div>

            {/* <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Vehicle</label>
              <div>
                <span className='font-14 text-dark'>CANTER - T 3783 DTT</span>
              </div>
            </div> */}

            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Sales Amount</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(order?.sales_amount)
                    ? '0'
                    : numberFormatWithCode(order?.sales_amount).displayFormat}
                </span>
              </div>
            </div>

            <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Credit Collection Amount</label>
              <div>
                <span className='font-14 text-dark'>
                  {isEmpty(order?.credit_approved_amount)
                    ? '0'
                    : numberFormatWithCode(order?.credit_approved_amount).displayFormat}
                </span>
              </div>
            </div>

            {/* <div className='col-sm-6 col-xl-3 mb-6'>
              <label className='font-14 text-muted mb-2'>Route Jumps</label>
              <div>
                <span className='font-14 text-dark'>2</span>
              </div>
            </div> */}
          </div>
        </>
      </div>
    </>
  )
}

export default RouteDetails
